.critical-flights-conf {
  height: 100%;
  margin-right: 3.5vh;
  min-width: 24em;
}

.crit-flights-title {
  font-family: "Latam Sans Bold";
  font-size: 2.8vh;
  color: #150067;
  margin-bottom: 3.5vh;
  min-width: 10em;
}

.crit-flights-tab {
  font-family: "Latam Sans Regular";
  color: #1A86F4;
  font-size: 2vh;
}

.crit-flights-filters, .crit-flights-sorts {
  margin-top: 3vh;
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 10vh;
  row-gap: 3vh;
  width: 100%;
}

.desc {
  font-family: "Latam Sans Regular Italic";
  font-size: 2vh;
  font-weight: 400;
  color: #32314B;
  margin-top: 3vh;
}

.react-tabs__tab--selected {
  color: #32314B;
}

.crit-flights-buttons {
  margin-top: 5vh;
}

.last-mod {
  font-size: 1.6vh;
  font-style: italic;
  color: #31363F;
  margin-bottom: 2vh;
}

.crit-flights-autocomplete {
  min-width: 35vh;
}


#multiple-limit-tags-label {
  font-size: 1.8vh !important;   
  font-family: "Latam Sans Regular" !important;
  color: #32314B !important;
}

.option {
  font-size: 1.8vh !important;
  color: #32314B !important;
  font-family: "Latam Sans Regular" !important;
}

.MuiAutocomplete-option {
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
}

.MuiAutocomplete-option[data-focus="true"], .MuiAutocomplete-option[aria-selected="true"] {
  background-color: rgba(197, 220, 250, 0.3) !important;    
}

.ac-label, .select-label {
  color: #32314B;
  font-size: 1.8vh;
  margin: 0.5vh 1vh;
}

.ac-tag {
  font-family: "Latam Sans Regular" !important;
  font-size: 1.4vh !important;
}

.ac-tag svg {
  width: 2.3vh;
  height: 2.3vh;
}

.ac-checkbox {
  color: #32314B;
}

.ac-checkbox svg {
  width: 2.3vh;
  height: auto;
}

.MuiOutlinedInput-root:hover fieldset{
  border-color:  #2684FF !important;
}

.sort-label {
  color: #32314B;
  font-size: 1.8vh;
  font-weight: bold;
  margin-bottom: 1vh;
}

/* Start Drag and Drop styles */

.crit-flights-drag-drop-sort {
  min-width: 18vh;
}

.drag-drop-body {
  background: #CBD6E2;
  border-radius: 5px;
  padding: 1vh 1.5vh;
}

.drag-drop-item {
  display: inline-flex;
  font-size: 1.8vh;
  width: 100%;
  margin-bottom: 0.5vh;
  cursor: move;
  background: #CBD6E2;
}

.drag-drop-item .number {
  margin-right: 1vh;
  color: #616871;
  font-weight: bold;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.drag-drop-item .option {
  font-family: "Latam Sans Regular" !important;
  width: 75%;
  background: #F7F7F7;
  border-radius: 5px;
  padding: 1vh;
}

.drag-drop-item .icon {
  float: right;
  color: #10191D;
  font-size: 1.8vh;
  width: 2vh;
  height: 2vh;
}

.dragged .drag-drop-item {
  opacity: 0.25;
}

.floating .drag-drop-item {
  box-shadow: 0 4px 20px #666666;
}

.dl-item.floating {
  width: 85%;
}

.dl-item {
  cursor: move;
}

/* End Drag and Drop styles */

/* Start Select styles */
.crit-flights-select {
  width: 35vh;
}

.form-select {
  min-width: 100% !important;
  max-width: 35vh;
}

.outlined-input {
  padding-top: 1vh !important;
  padding-bottom: 1vh !important;
  padding-left: 1vh !important;
}

.select:focus {
  background-color: transparent !important;
}

.MuiListItem-root.Mui-selected {
  background-color: rgba(197, 220, 250, 0.5) !important;
}

/* End Select styles */
