.vw-alert {
    font-family: "Latam Sans Regular" !important;
    font-weight: bold !important;
    padding: 0.7vh 1.8vh !important;
    font-size: 1.6vh !important;
    letter-spacing: normal !important;
}

.vw-alert svg {
    width: 1.8vh !important;
    height: auto !important;
}