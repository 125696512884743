.contingency-card{
  display: flex;
  flex-wrap: wrap;
  background-color: #363636;
  margin: 1vh;
  margin-top: 0;
  padding: 1vh;
  height: 85vh;
}

.contingency-card p{
  margin: 0;
}

.vr{
  border-left: 1px solid gray;
  height: 12vh;
  position: relative;
  top: 1.2vh;
}
  
.contingency-header{
  display: flex;
  height: 6vh;
  width: 100%
}

.contingency-header > .generic-title{
  width: 40%;
  margin: 0%;
}

.countergrp-a{
  display: inline-flex;
  margin-left: 3.9%;
  width: 30.4%;
  font-size: 2.45vh;
}

.countergrp-b{
  display: inline-flex;
  margin-left: 3.9%;
  margin-right: 0.4%;
  width: 30.4%;
  font-size: 2.45vh;
}

.contingency-flight-cards{
  width: 100%;
  height: 100vh;
  margin-top: 1vh;
}

.carousell-dots{
  text-align: center;
}

