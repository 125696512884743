.critical-flights {
  height: 48.2vh;
  width: 100%;
  border-radius: 4px;
  background: #363636;
}

.critical-flights .critical-header {
  display: flex;
  justify-content: space-between;
  height: 8vh;
  padding-left: 1%;
}

.critical-flights > .critical-header > .generic-title {
  width: 60%;
}

.critical-flights .critical-flights-main {
  margin: 0 auto;
  width: 100%;
  height: 32vh;
}

.critical-flights .cf-counter-grp {
  display: inline-flex;
  padding-top: 1vh;
  margin-right: 1vh;
}

.critical-flights .cf-counter-grp-b {
  display: inline-flex;
  margin: 0;
}

.critical-flights .cf-carousell-dots {
  display: block;
  text-align: center;
  width: 100%;
  height: 4.1vh;
}

.critical-flights .cf-dot {
  cursor: pointer;
  height: 1.6vh;
  width: 1.6vh;
  margin: 0 0.4vh;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.critical-flights .cf-divisor {
  display: inline-block;
  position: relative;
  top: 37.3vh;
  left: 8.7%;
  width: 73vh;
  height: 0.4vh;
  background-color: #000000;
  transform: rotate(90deg);
  opacity: 80%;
  margin-right: 5%;
}
