.dialog {
    padding: 1vh 1vh 0 1vh;
}

.dialog-title {
    font-family: "Latam Sans Bold";
    font-size: 2.2vh;
}

.dialog-content {
    font-family: "Latam Sans Regular";
    font-size: 1.8vh;
}

.dialog-button {
    font-family: "Latam Sans Regular" !important;
    font-size: 1.8vh !important;
    font-weight: bold !important;
    color: #1A86F4 !important;
    text-transform: none !important;
    padding: 1vh 2vh !important;
}