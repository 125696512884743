.critical-aircraft {
  display: flex;
  flex-direction: column;
  background-color: #363636;
  height: 87vh;
  margin-left: 1vh;
  white-space: nowrap;
}

.critical-aircraft .ca-counter-grp {
  display: inline-flex;
  width: 35%;
  margin-left: 12%;
  margin-right: 1vh;
}

.critical-aircraft .critical-aircraft-header {
  display: flex;
  width: 100%;
  height: 8vh;
  margin-left: 0.4%;
  margin-right: 0.5%;
}

.critical-aircraft .critical-aircraft-main {
  display: flex;
  width: 97.5%;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 1.2%;
  margin-right: 0.9%;
}

.no-data {
  color: white;
  font-weight: bold;
  font-style: normal;
  margin-top: 2vh;
  font-size: 3vh;
  text-align: center !important;
}

.no-information {
  color: white;
  font-size: 22px;
  text-align: center;
  margin-top: 10vh;
}

.without-pax {
  margin-top: 0 !important;
}

.slider-pax>div {
  overflow: inherit;
}