.sl-counter{
  display: inline-flex;
  flex-wrap: wrap;
  background-color: darkolivegreen;
  color: white;
  width: 4.9vh;
  border-radius: 1vh;
  font-weight: bold;
  height: 6vh;
  margin-right: 0.2vh;
  padding-top: 0.4vh;
}

.sl-counter p{
  margin: 0;
}

.sl-counter-title{
  font-family: "Lato Bold Italic";
  text-align: center;
  width: 100%;
  font-size: 2vh;
}

.sl-counter .sl-num{
  margin: 0;
  text-align: center;
  width: 100%;
  font-size: 2.4vh;
}
