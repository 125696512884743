.sl-dot {
  cursor: pointer;
  height: 1.6vh;
  width: 1.6vh;
  margin: 0 0.4vh;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.sl-carousell-dots .active, .sl-dot:hover {
  background-color: #717171;
}
  
  /* Fading animation */
.sl-fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}