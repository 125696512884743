.data-filters {
    height: 100%;
    margin-right: 3.5vh;
    min-width: 22em;
}

.df-title {
    font-family: "Latam Sans Bold";
    font-size: 2.8vh;
    color: #150067;
    min-width: 10em;
}

.df-config {
    margin-top: 3vh;
}

.df-select-filiales .desc{    
    font-size: 2vh;
    font-style: italic;
    font-weight: 400;
    color: #32314B;
    margin-bottom: 2vh;
    white-space: break-spaces;
}

.filial {
    font-family: "Latam Sans Bold";
    font-size: 1.8vh;
    color: #32314B;
    width: 2vh;
}

.cb-label {
    color: #4C4C4C;
    margin-left: 3vh !important;
    margin-right: 0 !important;
}

.cb-typo {
    font-size: 1.6vh !important;
    font-family: "Latam Sans Regular" !important;
}

.df-buttons {
    margin-top: 5vh;
}

.last-mod {
    font-size: 1.6vh;
    font-style: italic;
    color: #31363F;
    margin-bottom: 2vh;
}

.form {
    width: 25vh;
    padding-left: 1vh;
}

.fg-row:hover {
    background-color: rgba(197, 220, 250, 0.3);
    backdrop-filter: blur(4px);
}

