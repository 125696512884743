.theader-br {
  background-color: rgba(169, 172, 175, 0.267);
  color: #31363F;
  /*width: 68px;*/
  width: 10%;
}

.table-br {

  font-family: "Latam Sans Regular Italic";
  font-size: 14px;
  font-weight: lighter !important;
  font-style: italic;
  width: 1000px;
  color: #31363F;
  padding: 0px;
}

.doble-br {
  height: 25px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(111, 113, 116) !important;
  border-collapse: collapse;
  padding: 0px;
}

.simple-br {
  height: 25px;
  width: 55px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  padding: 0px;

  border-collapse: collapse;
}

.rojo-br {
  background-color: #ED1650;
  padding: 0px;
}

.amarillo-br {
  background-color: #FFC700;
  padding: 0px;
}

.borde-br {
  border-left: 1px solid rgb(111, 113, 116);
}

.col1-br {
  width: 68px;
}

.input-br {
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
}

.main-br {
  width: 100%;
  overflow-x: scroll;
}

.show-br {
  display: block;
}

.hidden-br {
  display: none;
}
.scheduler{
  background: rgba(169, 172, 175, 0.267);
  color: #31363F;
  height: 34px;
  border: 1px solid rgb(111, 113, 116) !important;
  width: 22%;
}
.col-br{
  width: 10%;
}
.borde-final{
  border: 1px solid rgb(111, 113, 116) !important;
}