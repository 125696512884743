.dot3 {
  cursor: pointer;
  height: 1.6vh;
  width: 1.6vh;
  margin: 0 0.4vh;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.cf-carousell-dots .active,
.dot3:hover {
  background-color: #717171;
}

/* Fading animation */
.cf-fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

.crew {
  overflow: hidden;
}

.crew .summary-limops-col-ac,
.crew .summary-limops-col {
  font-size: 2vh;
}

.crew .summary-limops-col.ato {
  font-size: 1.5vh;
}

.crew .summary-limops-row-style {
  background-color: transparent;
  margin-bottom: 1px;
  margin-top: 0.3vh;
  padding-top: 0;
}

.crew .summary-limops-row-style div {
  background-color: #10191d;
  border-radius: 6px;
  /* font-size: 2vh; */
}

.crew .summary-limops-row-style .red {
  border-left: 5px solid rgb(235, 87, 87);
  background-color: #10191d !important;
  margin-left: 0.3vh;
}

.crew .summary-limops-row-style .red-small {
  border-left: 5px solid rgb(235, 87, 87);
  background-color: #10191d !important;
  margin-left: 0.3vh;
  font-size: 1.4vh !important;
}

.crew .summary-limops-row-style .yellow {
  border-left: 5px solid rgb(254, 197, 50);
  background-color: #10191d !important;
}

.crew .summary-limops-row-style .yellow-small {
  border-left: 5px solid rgb(254, 197, 50);
  background-color: #10191d !important;
  font-size: 1.4vh !important;
}

.crew .summary-limops-row-style .white {
  border-left: 5px solid white;
  background-color: #10191d !important;
}

.crew .summary-limops-row-style .white-small {
  border-left: 5px solid white;
  background-color: #10191d !important;
  font-size: 1.4vh !important;
}

.crew .summary-limops-row-style .black {
  border-left: 5px solid #10191d;
  background-color: #10191d !important;
}

.crew .summary-limops-row-style .black-small {
  border-left: 5px solid #10191d;
  background-color: #10191d !important;
  font-size: 1.4vh !important;
}

.crew .summary-limops-row-style div:first-child {
  border: none;
  font-size: 2vh !important;
  width: 22% !important;
}

.crew .summary-limops-sep {
  width: auto;
}

.crew .title {
  background-color: #10191d;
  width: 99.4%;
}

.crew .title div {
  border: none;
  position: relative;
}

.crew .title .summary-limops-col::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: gray;
}

.header div {
  font-size: 2.5vh !important;
  border: none !important;
  margin: 0.1vh;
  border-radius: 5px;
  background-color: #10191d;
}

.oh {
  overflow: hidden;
  visibility: hidden;
  width: 18%;
}

.crew-standby-main .title {
  background-color: #10191d !important;
  /* border-radius: 2vh;
  -webkit-border-radius: 2vh;
  -moz-border-radius: 2vh;
  -ms-border-radius: 2vh;
  -o-border-radius: 2vh; */
}

.ssc {
  width: 22% !important;
  font-size: 12vh !important;
}

.ato {
  font-size: 1.8vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
