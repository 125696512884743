.phone-dialog {
    max-width: none;
    width: 60vh;
    padding: 1%;
}

.dialup-modal{
    font-family: Latam Sans Bold;
    width: 100%;
    max-height: 100vh;
    margin: auto;
    background-color: white;
    border-radius: 1.2vh;
    outline: none;
}
  
.dialup-modal .modal-title{
    font-size: 2.7vh;
    color: #150067;
    text-align: center;
    margin: 0 auto;
    margin-top: 1vh;
    width: 100%;
}
  
.dialup-modal .modal-subtitle{
    font-size: 2vh;
    text-align: center;
    margin: 1vh auto;
    margin-bottom: 2vh;
    width: 100%;
}

.dialup-modal .dialup-modal-extensions{
    text-align: center;
    width: 100%;
    
}

.dialup-modal .dialup-extension-one{
    width: 28%;
    margin-right: 1%;
}
  
.dialup-modal .dialup-extension-two{
    width: 42%;
    margin-left: 1%;
}
  
.dialup-modal .select-extension-one div:first-of-type{
    font-size: 2vh;
    text-align: left;
}
  
.dialup-modal .select-extension-two div:first-of-type{
    font-size: 2vh;
    text-align: left;
}

.dialup-modal .dialup-modal-base{
    padding-left: 1vh;
}
  
.dialup-modal .dialup-row{
    font-family: Roboto;
    color: #4A4A4A;
    font-weight: normal;
    font-size: 1.7vh;
    display: flex;
    margin-bottom: 1.5vh;
}

.dialup-modal .dialup-row .disable{
    pointer-events: none;
    opacity: 0.5;
}

.dialup-modal .dialup-row .dialup-left{
    width: 50%;
    overflow: hidden;
    margin-right: 1%;
}

.dialup-modal .dialup-row .dialup-bold  {
    font-weight: bold;
}

.dialup-modal .dialup-row .dialup-middle{
    width: 40%;
}

.dialup-modal .dialup-row .dialup-right{
    cursor: pointer;
}
  
.dialup-modal .contacts-label{
    font-family: 'Latam Sans Bold';
    color: #10191D;
    text-align: center;
    font-size: 2vh;
}
  
.dialup-modal .airport{
    font-family: Roboto;
    font-weight: bold;
    color: #4A4A4A;
    font-size: 2vh;
}
  
div.dialup-modal > div.modal-extensions > div.dialup-modal-contacts{
    margin: auto auto;
    width: 81% !important;
}
  
.dialup-modal .dialup-modal-contacts > div:first-of-type > div:first-of-type{
    padding: 1vh 1vh;
}
  
 
.dialup-modal .numbers-body{
    width: 100%;
    height: 50vh;
    margin-top: 3vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
      
.dialup-modal .numbers-body .dialup-row{
    width: 100%;
}
  
.dialup-modal .modal-extensions fieldset legend{
    max-width: 0;
}
  
.dialup-modal .modal-cross{
    display: block;
    float: right;
    height: 2vh;
}
  
.dialup-modal .modal-cross:hover{
    cursor: pointer;
}