


.percent-data-veo{
  font-family: 'Latam Sans Bold';
  display: block;
  text-align: center;
  font-size: 2.5vh;
}
.veo-title-font{
  font-family: Latam Sans rgb(255, 255, 255) !important;
  font-size: 2vh  !important;
}
.latam-logo-veo{
  height: 3vh;
}
.veo-punct-body{
  display: flex;
  color: white;
}
.veo-punct-col1{
  color: white;

}
.veo-punct-col2{
  width: 100%;
  color: white;
  margin-left: 5%;
}
.veo-punct-rows{
  display: flex;
  font-family: 'Latam Sans Bold';
  width: 100%;
  height: 55px;
  align-items: center;
  text-align: center;
}
.veo-punct-delays{
  overflow: hidden;
}

.veo-punctuality{
  background-color: #363636;
  height: 60%;
  width: 100%;
  border-radius: 0.6vh;
  margin-top: 0.4vh;
  color: white;
}

.veo-punctuality-title{
  font-family: Latam Sans Extended;
  font-size: 2.3vh;
  color: white;
  margin: 0;
  font-weight: bold;
  margin-top: 0.4vh;
  padding-top: 0.4vh;
}

.veo-punct-titles{
  font-family: Latam Sans Black;
  color: white;
  display: flex;
  margin: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2.2vh;
  line-height: 2.4vh;
}

.veo-punct-titles > span{
  width: 12.5%;
  white-space: break-spaces;
}
.veo-title-cell1{
  width: auto;
  margin-left: 0%;
}
.veo-title-cell2{
  width: auto;
  margin-left: 1%;
}
.veo-title-cell3{
  width: auto;
  margin-left: 2%;
}
.veo-title-cell4{
  width: auto;
  margin-left: 8%;
}
.veo-title-cell5{
  width: auto;
  margin-left: 5%;
}
.veo-title-cell6{
  width: auto;
  margin-left: 14%;
}

.veo-punctuality-unit{
  background-color: #10191D;
  width: 97%;
  border-radius: 0.4vh;
  align-items: center;
  margin: auto;
}

.veo-punct-unit-table{
  width: 100%;
  height: 42vh;
}

.veo-punct-row{
  display: flex;
  font-family: 'Latam Sans Bold';
  width: 100%;
  height: 16%;
  align-items: center;
  text-align: center;
}

.veo-title-cell1{
  width: 12.5%;
}
.veo-title-cell2{
  width: 12.5%;
}
.veo-title-cell3{
  width: 12.5%;
}
.veo-title-cell4{
  width: 12.5%;
  margin-left: 2%;
  margin-right: 1%;
}
.veo-title-cell5{
  
  width: 1.5%;
}
.veo-title-cell6{
  width: 16%;
}

.veo-punct-cell1{
  font-family: Latam Sans Black;
  font-size: 2.4vh;
  width: 20%;
  text-align: center;
  /* background-color: lightblue; */
}
.veo-punct-cell2{
  width: 12.5%;
  /* background-color: lightcoral; */
}
.veo-punct-cell3{
  width: 15.5%;
  font-size: 2.6vh;
  /* background-color: lightblue; */
}
.veo-punct-cell4{
  width: 12.5%;

  /* background-color: lightcoral; */
}
.veo-punct-cell5{
  margin-left: 2%;
  margin-right: 1%;
  width: 12.5%;

  /* background-color: lightblue; */
}

.veo-punct-cell6{
  width: 17.5%;
  font-size: 2.4vh;
}

.veo-punct-delays-cell{
  width: 20%;
  /* background-color: lightcoral; */
}

.veo-punct-delays-cell div:nth-of-type(1){
  border-right: 1px dashed gray;
  /* background-color: lightcoral; */
}

.veo-punct-delays-cell div:nth-of-type(2){
  border-right: 1px dashed gray;
  /* background-color: lightcoral; */
}

.veo-punct-delays-cell div:last-of-type{
  border-right: none !important;
}

.veo-punctuality .veo-delay-unit{
  display: inline-block;
  width: 32%;
  height: 100%;
}

.veo-punctuality .veo-delay-unit > p{
  font-weight: bold;
  font-size: 2.2vh;
  margin: 0;
}

.veo-punctuality .delay-code{
overflow: hidden;
}

.veo-punctuality .delay-value{
  overflow: hidden;
}

.veo-punctuality > div:last-of-type > div:last-of-type{
  margin-top: 0;
}

@media (max-width: 1200px) {

  .percent-data-veo{
    font-size: 1.7vh;
    font-family: 'Latam Sans Bold';
  }
  .veo-punct-cell2{
    margin-right: 4%;
  }
  .veo-punct-cell3{
    font-size: 1.7vh;
    font-family: 'Latam Sans Bold';
    margin-right: 4%;
  }
  .veo-punct-cell4{
  margin-right: 4%;
  }
  .veo-punct-cell5{
  margin-right: 4%;
  }
  .veo-punct-cell6{
    margin-right: 4%;
    width: 12.5%;
    font-size: 1.7vh;
    font-family: 'Latam Sans Bold';
  }
  .delay-unit>p{
    font-size: 1.7vh;
    font-family: 'Latam Sans Bold';
  }
  .veo-title-cell1{
    width: 15%;
  }
  .veo-title-cell2{
    width: 18%;
    margin-left: 0%;
  }
  .veo-title-cell3{
    width: 14%;
    margin-left: 0%;
  }
  .veo-title-cell4{
    width: 13%;
    margin-left: 0%;
  }
  .veo-title-cell5{
    width: 18%;
    margin-left: 0%;
  }
  .veo-title-cell6{
    width: 18%;
    margin-left: 0%;
  }
  .veo-title-font{
    font-family: Latam Sans rgb(255, 255, 255) !important;
  }
  @media (max-height: 958px) {
    .percent-data-veo{
      font-size: 1.7vh;
      font-family: 'Latam Sans Bold';
    }
    .veo-punct-cell2{
      margin-right: 4%;
    }
    .veo-punct-cell3{
      font-size: 1.7vh;
      font-family: 'Latam Sans Bold';
      margin-right: 4%;
    }
    .veo-punct-cell4{
    margin-right: 4%;
    }
    .veo-punct-cell5{
    margin-right: 4%;
    }
    .veo-punct-cell6{
      margin-right: 4%;
      width: 12.5%;
      font-size: 1.7vh;
      font-family: 'Latam Sans Bold';
    }
    .delay-unit>p{
      font-size: 1.7vh;
      font-family: 'Latam Sans Bold';
    }
    .veo-title-cell1{
      width: 15%;
    }
    .veo-title-cell2{
      width: 20%;
      margin-left: 0%;
    }
    .veo-title-cell3{
      width: 15%;
      margin-left: 0%;
    }
    .veo-title-cell4{
      width: 15%;
      margin-left: 0%;
    }
    .veo-title-cell5{
      width: 15%;
      margin-left: 0%;
    }
    .veo-title-cell6{
      width: 15%;
      margin-left: 0%;
    }
    .veo-title-font{
      font-family: Latam Sans rgb(255, 255, 255) !important;
    }
  }
}
@media (max-width: 500px){
  .percent-data-veo{
    font-size: 12px !important;
    font-family: 'Latam Sans Bold';
  }
  .veo-punctuality-title{
    color: white;
  }
  .veo-punct-body{
    color: white;
    width:auto;
  }
  .veo-punct-col1{
    margin-left: 0;
    background-color: transparent;
    color: white;
    width: 110px;
  }
  .veo-punct-col2{
    margin-left: 22px;
    background-color: transparent;
    width: 414px;
    color: white;
    overflow-x: auto;
  }
  .veo-punct-rows{
    display: flex;
    font-style: normal;
    width: 495px;
    height: 50px;
    align-items: center;
    text-align: center;
  }
  .veo-punct-cell2{
    margin-right: 0%;
    width: 80px;
  }
  .veo-punct-cell3{
    font-size: 12px !important;
    font-family: 'Latam Sans Bold';
    margin-right: 0%;
    width: 80px;
  }
  .veo-punct-cell4{
  margin-right: 3%;
  width: 80px;
  }
  .veo-punct-cell5{
  margin-right: 0%;
  width: 70px;
  }
  .veo-punct-cell6{
    margin-right: 0%;
    width: 85px;
    font-size: 12px !important;
    font-family: 'Latam Sans Bold';
  }
  .delay-unit>p{
    font-size: 12px !important;
    font-family: 'Latam Sans Bold';
  }
  .veo-title-cell1{
    width: 80px;
    margin-left: 0%;
  }
  .veo-title-cell2{
    width: 80px;
    margin-left: 0%;
  }
  .veo-title-cell3{
    width: 80px;
    margin-left: 0%;
  }
  .veo-title-cell4{
    width: 80px;
    margin-left: 0%;
  }
  .veo-title-cell5{
    width: 85px;
    margin-left: 0%;
  }
  .veo-title-cell6{
    width: 60px;
    margin-left: 0%;

  } 
  .veo-punct-delays{
    width:20px
  }
  .veo-title-font{
    font-family: Latam Sans rgb(255, 255, 255) !important;
    font-size: 13px  !important;
  }
  
}
@media (max-width: 414px) {
.percent-data-veo{
  font-size: 1.7vh;
  font-family: 'Latam Sans Bold';
}
.veo-punctuality-title{
  color: white;
}
.veo-punct-body{
  color: white;
  width:100%;
}
.veo-punct-col1{
  margin-left: 0;
  background-color: transparent;
  color: white;
  width: 110px;
}
.veo-punct-col2{
  margin-left: 22px;
  background-color: transparent;
  width: 100%;
  color: white;
  overflow-x: auto;
}
.veo-punct-rows{
  display: flex;
  font-style: normal;
  width: 200%;
  height: 50px;
  align-items: center;
  text-align: center;
}
.veo-punct-cell2{
  margin-right: 1.6%;
  width: 15%;
}
.veo-punct-cell3{
  margin-right: 1.6%;
  width: 15%;
  font-size: 1.7vh;
  font-family: 'Latam Sans Bold';

}
.veo-punct-cell4{
  margin-right: 1.6%;
  width: 15%;
}
.veo-punct-cell5{
  margin-right: 1.6%;
  width: 15%;
}
.veo-punct-cell6{
  margin-right: 1.6%;
  width: 15%;
  font-size: 1.7vh;
  font-family: 'Latam Sans Bold';
}
.delay-unit>p{
  font-size: 1.7vh;
  font-family: 'Latam Sans Bold';
}
.veo-title-cell1{
  width: 15%;
  margin-left: 1.6%;
}
.veo-title-cell2{
  width: 15%;
  margin-left: 1.6%;
}
.veo-title-cell3{
  width: 15%;
  margin-left: 1.6%;
}
.veo-title-cell4{
  width: 15%;
  margin-left: 1.6%;
}
.veo-title-cell5{
  width: 15%;
  margin-left: 1.6%;
}
.veo-title-cell6{
  width: 15%;
  margin-left: 1.6%;
}
.veo-punct-delays-cell{
  width: auto;
  margin-left: 0;
}
.veo-punct-delays{
  width:22px
}

}

@media (max-width: 375px) {

  .row-percent-box-veo{
    width: 80%;
    height: 60%;
  }

.percent-data-veo{
  font-size: 12px !important;
  font-family: 'Latam Sans Bold';
}
  .veo-punct-delays{
  width: 34px;
  height: 19px;

}
  
.veo-table{
  color: white;

}
.veo-punctuality-title{
  color: white;
}
.veo-punct-rows{
  font-size: 10px !important;
  height: 40px;
  font-family: 'Latam Sans Bold';
}
.veo-punct-body{
  display: flex;
  color: white;
}


.delay-unit>p{
  font-size: 12px !important;
  font-family: 'Latam Sans Bold';
}

.veo-punct-delays{
  width:24px
}

}

@media (max-width: 320px){

  .veo-punct-rows{
    font-size: 10px !important;
    height: 35px;
    font-family: 'Latam Sans Bold';
  }
}



  

