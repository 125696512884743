div.fixed-action-btn.direction-top.active{
    background-color: transparent;
}

.fixed-action-btn {
  position: fixed !important;
  right: 4vh !important;
  bottom: 5vh !important;
  left: auto !important;
  top: auto !important;
}

li .tooltiptext {
    font-family: 'Roboto';
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* li .tooltiptext::after {
    content: "";
    position: relative;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  } */
  
  li:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }