.w-filters {
  margin-top: 5vh;
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 10vh;
  row-gap: 3vh;
  width: 100%;
  left: 21px;
  position: relative;
}

.theader-ssc {

  position: relative;           
  margin: 4px;                    
  border-radius: 8px;              
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3); 
  overflow: hidden;               
  padding: 10px;                
  text-align: center;              
  vertical-align: middle;         


  
}

.table-ssc {
  font-family: "Latam Sans Regular Italic";
  font-size: 14px;
  font-weight: lighter !important;
  width: 78%;
  color: #000000;
  padding: 0px;
  border-collapse: separate;
  overflow: visible;
  /* border-spacing: 6px; */
  /* table-layout: fixed !important; */
  left: 31px;
  position: relative;
  border-spacing: 0px 8px;
  top: 51px;
}

.doble-ssc {
  width: 68px !important;
  height: 26px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  border-radius: 5px;
}
.doble-ssc-ato-mando-a-0{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 17px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-mando-a-1{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 18px;
  overflow: hidden;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-mando-a-2{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 20px;
  overflow: hidden;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-mando-a-3{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 21px;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-mando-r-0{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 16px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-mando-r-1{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 17px;
  overflow: hidden;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-mando-r-2{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 18px;
  overflow: hidden;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-mando-r-3{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 19px;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}



.doble-ssc-ato-cabina-a-0{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 14px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-cabina-a-1{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 15px;
  overflow: hidden;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-cabina-a-2{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 16px;
  overflow: hidden;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-cabina-a-3{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 17px;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-cabina-r-0{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 12px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-cabina-r-1{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 13px;
  overflow: hidden;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-cabina-r-2{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 14px;
  overflow: hidden;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-ato-cabina-r-3{
  width: 0px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 15px;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: -5px 0px 0px -5px rgba(0, 0, 0, 0.1), 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}






.container-inputs-mando{
display: contents;
}
.container-inputs-mando-pm{
  display: contents;
  }
.container-inputs-ato-mando-a{
  display: contents;
}
.container-inputs-ato-mando-r{
  display: contents;
}
.container-inputs-ato-cabina-a{
  display: contents;
}
.container-inputs-ato-cabina-r{
  display: contents;
}
.container-inputs-mando-t24{
  display: contents;
}

.doble-ssc-mando-a {
  width: 30px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 37px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);


}
.doble-ssc-mando-r {
  width: 30px !important;
  height: 26px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 38px;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-mando-a-pm {
  width: 30px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 27px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-mando-r-pm {
  width: 30px !important;
  height: 26px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 28px;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-mando-a-t24 {
  width: 30px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 10px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-mando-r-t24 {
  width: 30px !important;
  height: 26px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 11px;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.container-inputs-cabina{
  display: contents;

}
.container-inputs-cabina-pm{
  display: contents;

}
.container-inputs-cabina-t24{
  display: contents;

}
.doble-ssc-cabina-a {
  width: 56px !important;
  height: 22px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(204 204 204) !important;
  border-collapse: collapse;
  padding: 0px;
  background-color: #f6f6f8;
  position: relative;
  right: 32px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
}
.doble-ssc-cabina-r {
  width: 56px !important;
    height: 26px;
    font-weight: lighter !important;
    text-align: center;
    vertical-align: middle;
    border: 1px solid rgb(204 204 204) !important;
    border-collapse: collapse;
    padding: 0px;
    background-color: #f6f6f8;
    position: relative;
    right: 33px;
    overflow: hidden;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
  }
  .doble-ssc-cabina-a-pm {
    width: 56px !important;
    height: 22px !important;
    font-weight: lighter !important;
    text-align: center;
    vertical-align: middle;
    border: 1px solid rgb(204 204 204) !important;
    border-collapse: collapse;
    padding: 0px;
    background-color: #f6f6f8;
    position: relative;
    right: 22px;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
  }
  .doble-ssc-cabina-r-pm {
    width: 56px !important;
      height: 26px;
      font-weight: lighter !important;
      text-align: center;
      vertical-align: middle;
      border: 1px solid rgb(204 204 204) !important;
      border-collapse: collapse;
      padding: 0px;
      background-color: #f6f6f8;
      position: relative;
      right: 23px;
      overflow: hidden;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
    }
  .doble-ssc-cabina-a-t24 {
    width: 62px !important;
    height: 22px !important;
    font-weight: lighter !important;
    text-align: center;
    vertical-align: middle;
    border: 1px solid rgb(204 204 204) !important;
    border-collapse: collapse;
    padding: 0px;
    background-color: #f6f6f8;
    position: relative;
    right: 6px;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
  }
  .doble-ssc-cabina-r-t24 {
    width: 62px !important;
    height: 26px;
    font-weight: lighter !important;
    text-align: center;
    vertical-align: middle;
    border: 1px solid rgb(204 204 204) !important;
    border-collapse: collapse;
    padding: 0px;
    background-color: #f6f6f8;
    position: relative;
    right: 7px;
    overflow: hidden;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 4px 1px rgba(0, 0, 0, 0.1);
  }
  


.none-ssc{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-mando-a-0{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-mando-a-1{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-mando-a-2{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-mando-a-3{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-mando-r-0{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-mando-r-1{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-mando-r-2{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-mando-r-3{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}


.none-ssc-ato-cabina-a-0{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-cabina-a-1{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-cabina-a-2{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-cabina-a-3{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-cabina-r-0{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-cabina-r-1{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-cabina-r-2{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-ato-cabina-r-3{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 19px;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}




.none-ssc-mando-a{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}

.none-ssc-mando-r{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-mando-a-pm{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}

.none-ssc-mando-r-pm{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-cabina-a{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-cabina-r{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-cabina-a-pm{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
  
}
.none-ssc-cabina-r-pm{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}

.none-ssc-mando-a-t24{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}

.none-ssc-mando-r-t24{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-cabina-a-t24{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}
.none-ssc-cabina-r-t24{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
  background-color: #f6f6f8;
  font-weight: 600;
  height: 20px;
}

.doble-ssc-a {
  width: 68px !important;
  height: 34px;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(111, 113, 116) !important;
  border-collapse: collapse;
  padding: 0px;
}

.simple-ssc {
  height: 28px;
  width: 55px !important;
  font-weight: lighter !important;
  text-align: center;
  vertical-align: middle;
  padding: 0px;

  border-collapse: collapse;
}

.rojo-ssc {
  background-color: #ED1650;

}

.amarillo-ssc {
  background-color: #FFC700;
}

.borde-ssc {
  border: 1px solid rgb(111, 113, 116);
}

.borde-ssc-turn{
  border: 1px solid rgb(111, 113, 116);
  border: none;
  border-collapse: collapse;
}

.col1 {
  width: 64px;
  position: relative;
  box-shadow: 0px 1px 3px #cccccc;
  border-radius: 4px;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  left: -44px;
  font-family: 'Latam Sans Bold';
  display: flex;
  justify-content: center;
  height: 24px;
  place-items: end;
  align-items: center;
}

.input {
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
}

.csb-header,
.csb-body,
.csb-color {
  display: flex;
}

.csb-header div {
  border-top: 1px solid black;
  color: black;
}

.csb-header div,
.csb-body div,
.csb-color div {
  width: 200px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  background-color: #EEEDF9;
  padding: 10px 0;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.csb-header div:first-child,
.csb-body div:first-child,
.csb-color div:first-child {
  width: 130px;
  border-left: 1px solid black;
  padding: 10px;
}

.csb-body div {
  padding: 0;
}

.csb-body p {
  color: black;
  width: 50%;
  margin: 0;
  line-height: 15px;
  padding: 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.csb-body p:first-child {
  border-right: 1px solid black;
}

.csb-color div {
  background-color: white;
  padding: 0;
  color: black;
}

.csb-color div .semaforo:last-child {
  border-right: none;
}

.semaforo {
  width: 50%;
  margin: 0;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
}

.bottom-section {
  margin-top: 10px;
  font-weight: bold;
  max-width: 650px;
  color: black;
  font-size: 13px;
}

.bottom-section div {
  display: flex;
}

.bottom-section p,
.bottom-section input {
  width: 160px;
  border-top: 0;
  border-left: 0;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  margin: 0;
  padding: 3px;
}

.bottom-section div:first-child p {
  border-top: 1px solid black;
}

.bottom-section div p:first-child {
  border-left: 1px solid black;
}

.mt-10 p {
  padding: 10px 3px;
}

.text-consider {
  color: black;
  font-size: 17px;
  margin: 30px 0 0 0;
}


.row-schedule{
  position: relative;
    z-index: 1;
    background-color: inherit;
    bottom: 34px;
    right: 35px;
    width: 10%;
    max-width: 150%;
}

.row-schedule-container {
      /* justify-content: stretch; */
      /* align-items: center; */
      /* height: 100%; */
  display: flex;
      /* flex-wrap: nowrap; */
  justify-content: center;
  align-items: center;
      /* width: 100% !important; */
  gap: 196px !important;
      /* padding: 0 !important; */
      /* margin: 0 !important;*/
}

.row-schedule-th{
  background-color: #F6F6F8;
  /* border: 1px solid #cccccc; */
  border-radius: 8px;
  padding: 27px;
  position: absolute;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  /* overflow: hidden; */
  bottom: 1px;
  display: table-cell;
  /* width: auto !important; */
  /* min-width: 14px !important; */
  /* max-width: 80px !important; */
  /* padding: 0px !important; */
  margin: 0px !important;
  /* text-align: center; */
  /* overflow: hidden; */
  /* white-space: nowrap; */
  /* border: 1px solid #cccccc !important; */
  width: 1016px !important;
  max-width: 91.7% !important;
  /* padding: 0 !important; */
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #cccccc !important;
  height: 1px;
  
}

/*.row-schedule-th {
  width: 100px !important;
  max-width: 100% !important;  
}*/

.row-schedule-box{
  text-align: center;
  padding: 15px 0;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px !important;
  color: #000000;
  flex-grow: 0 !important;
  min-width: 40px !important;
  max-width: 30px !important;
  width: 45px !important;
  padding: 2px 0 !important;
  font-size: 12px !important;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.am-ssc {
  font-size: 14px !important;
  position: relative;
  right: 62px;
  bottom: 7px;
}

.pm-ssc {
  font-size: 14px !important;
  position: relative;
  right: 53px;
  bottom: 7px;
}

.ato-ssc {
  flex-grow: 12;
  font-size: 14px !important;
  position: relative;
  right: -6px;
  bottom: 7px;
}

.t24-ssc {
  flex-grow: 7;
  font-size: 14px !important;
  position: relative;
  left: 61px;
  bottom: 7px;
}

.row-schedule-box:last-child {
  border-right: none;
}

.mando-box-am {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 96px;
  position: relative;
  bottom: 7px;
  margin-right: -2px;
  bottom: 34px;
  right: 38px;
}

.cabina-box-am {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 96px;
  position: relative;
  margin-right: -2px;
  bottom: 34px;
  right: 33px;
}
.mando-box-pm {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 96px;
  position: relative;
  margin-right: -2px;
  bottom: 34px;
  right: 28px;
}

.cabina-box-pm {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 96px;
  position: relative;
  margin-right: -2px;
  bottom: 34px;
  right: 23px;
}

.mando-box-ato {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 145px;
  position: relative;
  margin-right: -2px;
  bottom: 34px;
  right: 18px;
}
.cabina-box-ato {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 144px;
  position: relative;
  margin-right: -2px;
  bottom: 34px;
  right: 13px;
}
.mando-box-t24 {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-weight: 900;
  font-family: "Latam Sans Bold";
  font-size: 14px;
  color: #000000;
  background-color: #F6F6F8;
  border: 1px solid #cccccc;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 95px;
  position: relative;
  margin-right: -2px;
  bottom: 34px;
  right: 10px;
}

.cabina-box-t24 {
  padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    display: inline-block;
    font-weight: 900;
    font-family: "Latam Sans Bold";
    font-size: 14px;
    color: #000000;
    background-color: #F6F6F8;
    border: 1px solid #cccccc;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 96px;
    position: relative;
    margin-right: -2px;
    bottom: 34px;
    right: 6px;
}

.color-box-a-mando-am {
  border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    width: 55px;
    height: 21px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    left: -5px;
}

.color-box-r-mando-am {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 55px;
  height: 21px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 2px;
}
.color-box-a-cabina-am {
  border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    width: 55px;
    height: 21px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    left: 7px;
}

.color-box-r-cabina-am {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 55px;
  height: 21px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 14px;
}
.color-box-a-mando-pm {
  border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    width: 55px;
    height: 21px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    left: 19px;
}

.color-box-r-mando-pm {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 55px;
  height: 21px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 26px;
}
.color-box-a-cabina-pm {
  border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    width: 55px;
    height: 21px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    left: 31px;
}

.color-box-r-cabina-pm {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 55px;
  height: 21px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 38px;
}

.color-box-ato-a {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 23px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 1px;
}

.color-box-ato-r {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 22px;
  height: 22px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 3px;
}


.color-box-a-mando-t24 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 55px;
  height: 21px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 67px;
}

.color-box-r-mando-t24 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 55px;
  height: 21px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 74px;
}
.color-box-a-cabina-t24 {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 55px;
  height: 21px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 78px;
}

.color-box-r-cabina-t24 {
  border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    width: 55px;
    height: 21px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    left: 84px;
}

.color-row-container {
    display: flex;
    justify-content: flex-start;
    bottom: 38px;
    position: relative;
    right: 33px;
}
.color-th-container-ato{
  display: flex;
  gap: 1px;
}
.color-th-container-mando-am{
  display: flex;
  gap: 1px;
}
.color-th-container-cabina-am{
  display: flex;
  gap: 1px;
}
.color-th-container-mando-pm{
  display: flex;
  gap: 1px;
}
.color-th-container-cabina-pm{
  display: flex;
  gap: 1px;
}
.color-th-container-mando-t24{
  display: flex;
  gap: 1px;
}
.color-th-container-cabina-t24{
  display: flex;
  gap: 1px;
}

.row-inputs{
  position: relative;
  top: -38px;
}

.color-box-ato-a-mando {
  border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    width: 19px;
    height: 21px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    left: 44px;
  
}

.color-box-ato-r-mando {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 19px;
  height: 21px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 50px;
}

.color-box-ato-a-cabina {
  border-radius: 5px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  width: 19px;
  height: 21px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
  left: 56px;
}

.color-box-ato-r-cabina {
  border-radius: 5px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    width: 19px;
    height: 21px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    position: relative;
    left: 61px;
}

.body-inputs {
  width: 10px;
  position: relative;
  /* left: 13px;*/
}

.none-ssc, 
.none-ssc-mando-a, 
.none-ssc-mando-r, 
.none-ssc-cabina-a, 
.none-ssc-cabina-r {

    font-size: 14px;
    font-weight: 600;
}

.doble-ssc, 
.doble-ssc-mando-a, 
.doble-ssc-mando-r, 
.doble-ssc-cabina-a, 
.doble-ssc-cabina-r {
  /* height: 18px !important; 
  min-height: 18px; */
  padding: 0px; 
  line-height: 1;
  /* align-items: center; 
  justify-content: center; */
}

.none-ssc-ato-mando-a, 
.none-ssc-ato-mando-r, 
.none-ssc-ato-cabina-a, 
.none-ssc-ato-cabina-r {

  font-size: 14px;
  font-weight: 600;
}

.doble-ssc-ato-mando-a, 
.doble-ssc-ato-mando-r, 
.doble-ssc-ato-cabina-a, 
.doble-ssc-ato-cabina-r {
  height: 18px !important; /* Reduce la altura */
  min-height: 18px; /* Asegura que no crezca */
  padding: 0px; /* Elimina espacio extra */
  line-height: 1; /* Evita que el texto ocupe más espacio */
  align-items: center; /* Asegura alineación vertical */
  justify-content: center;
}


.w-tab.cs {
  font-family: "Latam Sans Regular";
  color: #1A86F4;
  font-size: 2vh;
}

.bold-text {
  font-weight: bolder;
  color: #020202;
  font-size: initial;
  font-family: "Latam Sans Regular";
  font-size: 14px;
}

.cf-buttons-cs {
  margin-top: 5vh;
  min-width: 16em;
  position: relative;
  right: 14px;
}
