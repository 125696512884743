.ca-table {
  width: 100%;
}

.ca-header {
  color: silver;
  font-weight: bold;
  text-align: center;
}

.ca-header-col {
  width: 33%;
  height: 100%;
  font-size: 2vh;
  color: white;
  background: #2d2d2d;
  padding: 7px 12px;
  display: inline-block;
  border-radius: 5px;
  max-width: max-content;
}

.ca-row {
  width: 100%;
  color: white;
  margin-top: 0.8vh;
  background-color: #10191D;
  border-radius: 0.4vh;
}

.ca-row-top {
  display: flex;
  width: 100%;
  height: 80%;
  font-size: 2.6vh;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Latam Sans Bold";
}

.ca-black-italic {
  font-family: "Latam Sans Black Italic";
}

.ca-col {
  display: inline-block;
  width: 33%;
  height: 60%;
  margin-top: 0.5vh;
  margin-bottom: 0.1vh;
}

.ca-border-left {
  border-left: 0.2vh solid gray;
}

.ca-left-cell {
  display: inline-block;
  width: 25%;
  height: 60%;
}

.ca-middle-cell {
  display: inline-block;
  width: 45%;
  height: 60%;
}

.ca-right-cell {
  display: inline-block;
  width: 30%;
  height: 60%;
}

.ca-cell-inline-2 {
  display: inline-block;
  width: 50%;
  height: 60%;
}

.ca-row-bottom {
  display: flex;
  border-radius: 0.4vh;
  width: 100%;
  height: 20%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Latam Sans Regular";
  text-transform: uppercase;
  overflow: hidden;
}

.ca-reason {
  font-size: 2.3vh;
  font-weight: normal;
  color: white;
  width: 100%;
  text-align: center;
}

.ca-zcool {
  font-family: 'ZCOOL';
  font-weight: normal;
}

.ca-italic {
  font-style: italic;
}