.row-percent-box{
  border: 0.2vh solid white;
  border-radius: 0.6vh;
  width: 10vh;
  height: 79%;
  margin: auto;
  border-left: 0.9vh solid red;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: 0.3vh;
}

.percent-data{
  font-family: 'Latam Sans Bold';
  display: block;
  text-align: center;
  font-size: 4vh;
}

.row-percent-box-veo{
  border: 0.2vh solid white;
  border-radius: 0.6vh;
  width: 84%;
  /* height: 79%; */
  margin: auto;
  border-left: 0.9vh solid red;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: 0.3vh;
}

.percent-data-veo{
  font-family: 'Latam Sans Bold';
  display: block;
  text-align: center;
  font-size: 3.8vh;
}
@media (max-width: 375px) {
  .percent-data-veo{
    font-family: 'Latam Sans Regular';
    font-size: 16px;
    display: block;
    text-align: center;
  }
  .row-percent-box-veo{
    border: 0.2vh solid white;
    border-radius: 0.6vh;
    width: 84%;
    /* height: 79%; */
    margin: auto;
    width: 54px;
    border-left: 10px solid red;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-top: 0.3vh;
  }

  .row-percent-box-veo{
    height: 25px;
    width: 64px;
 
  }
}