.critical-aircraft-config {
    height: 100%;
    margin-right: 3.5vh;
    min-width: 22em;
}

.cf-title {
    font-family: "Latam Sans Bold";
    font-size: 2.8vh;
    color: #150067;
    margin-bottom: 3.5vh;
    min-width: 10em;
}

.cf-tab {
    font-family: "Latam Sans Regular";
    color: #1A86F4;
    font-size: 2vh;
}

.cf-filters,
.cf-sorts {
    margin-top: 5vh;
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 10vh;
    row-gap: 3vh;
    width: 100%;
}

.cf-filters.block .option {
    display: flex;
    align-items: center;
    justify-content: center;
}

.desc {
    font-size: 2vh;
    font-style: italic;
    font-weight: 400;
    color: #32314B;
    margin-top: 2vh;
}

.react-tabs__tab--selected {
    color: #32314B;
}

.cf-buttons {
    margin-top: 5vh;
    min-width: 16em;
}

.last-mod {
    font-family: "Latam Sans Regular Italic";
    font-size: 1.6vh;
    color: #31363F;
    margin-bottom: 2vh;
}

.cf-autocomplete {
    min-width: 35vh;
}


#multiple-limit-tags-label {
    font-size: 1.8vh !important;
    font-family: "Latam Sans Regular" !important;
    color: #32314B !important;
}

.option {
    font-family: "Latam Sans Regular";
    font-size: 1.8vh !important;
    color: #32314B !important;
    font-family: "Latam Sans Regular" !important;
}

.MuiAutocomplete-option {
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}

.MuiAutocomplete-option[data-focus="true"],
.MuiAutocomplete-option[aria-selected="true"] {
    background-color: rgba(197, 220, 250, 0.3) !important;
}

.ac-label {
    font-family: "Latam Sans Regular";
    color: #32314B;
    font-size: 1.8vh;
    margin: 0.5vh 1vh;
}

.ac-tag {
    font-family: "Latam Sans Regular" !important;
    font-size: 1.4vh !important;
}

.ac-tag svg {
    width: 2.3vh;
    height: 2.3vh;
}

.ac-checkbox {
    color: #32314B;
}

.ac-checkbox svg {
    width: 2.3vh;
    height: auto;
}

.MuiOutlinedInput-root:hover fieldset {
    border-color: #2684FF !important;
}

.sort-label {
    color: #32314B;
    font-size: 1.8vh;
    font-weight: bold;
    margin-bottom: 1vh;
}

/* Start Drag and Drop styles */

.cf-drag-drop-sort {
    min-width: 18vh;
}

.drag-drop-body {
    background: #CBD6E2;
    border-radius: 5px;
    padding: 1vh 1.5vh;
}

.drag-drop-item {
    display: inline-flex;
    font-size: 1.8vh;
    width: 100%;
    margin-bottom: 0.5vh;
    cursor: move;
    background: #CBD6E2;
}

.drag-drop-item .number {
    margin-right: 1vh;
    color: #616871;
    font-weight: bold;
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.drag-drop-item .option {
    font-family: "Latam Sans Regular";
    width: 75%;
    background: #F7F7F7;
    border-radius: 5px;
    padding: 1vh;
}

.drag-drop-item .icon {
    float: right;
    color: #10191D;
    font-size: 1.8vh;
    width: 2vh;
    height: 2vh;
}

.dragged .drag-drop-item {
    opacity: 0.25;
}

.floating .drag-drop-item {
    box-shadow: 0 4px 20px #666666;
}

.dl-item.floating {
    width: 85%;
}

.dl-item {
    cursor: move;
}

/* End Drag and Drop styles */