.back-button{
    background-color: transparent;
    color: white;
    font-size: 2em;
    font-family:monospace;
    border: none;
    margin-left: 5%;
}

@media only screen and (max-width: 414px) {
    .back-button{
        margin-left: auto;
    }
    
}