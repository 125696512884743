html,
body {
  background-color: #31363f;
  overflow-y: hidden !important;
  height: 100%;
}

.VideoWall {
  display: flex;
  flex-wrap: wrap;
  /* min-width: 1920px;
  min-height: 1080px; */
  height: 100vh;
  width: 533vh;
  background-color: #000000;
  font-family: Latam Sans Regular;
}

.layout {
  display: inline-flex;
  /* width: 101.5vh; */
  width: 16.5%;
  height: 88vh;
  margin-left: 1vh;
  flex-wrap: wrap;
}
.temporal {
  /* background-color: #363636; */
  height: 87vh;
}
