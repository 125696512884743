.add-users-conf{
  font-family: "Latam Sans Regular" !important;
}

.add-users-conf button{
  font-family: "Latam Sans Bold" !important;
}

.add-users-conf .email-ctrl{
  width: 35vh;
  margin-right: 2vh;
}

.add-users-conf .rol-ctrl{
  width: 17vh;
  margin-right: 2vh;
}

.add-users-conf .cco-ctrl{
  width: 13vh;
  margin-right: 4vh;
}

.filter-email-ctrl{
  width: 35vh;
  margin-bottom: 2vh !important;
}

.add-users-conf .add-users-table {
  width: 70vh;
  margin-top: 2vh;
}

.add-users-conf .add-users-selects{
  margin-top: 2vh;
  display: inline-block;
}

.add-users-conf .add-btn{
  display: inline-block;
  position: relative;
  top: 1.5vh;
  font-size: 1.8vh !important;
  width: 5%;
  height: 10%;
  background-color:#030E87;
}

.export-btn{
  display: inline-block;
  position: relative;
  top: 1.5vh;
  font-size: 1.8vh !important;
  height: 10%;
  display: inherit;
  background-color:#030E87 !important;
  color: white;
  font-family: "Latam Sans Bold" !important;
  box-shadow: none;
  padding: 6px 16px;
  min-width: 64px;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  margin: 0;
  outline: 0;
  vertical-align: middle;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}


.export-btn:hover{
  background-color:#303f9f !important;
}

.users-table-cell-head {
  font-family: "Latam Sans Bold" !important;
  color: black !important;
  font-size: 2vh !important;
  padding: 0px !important;
  padding-bottom: 1vh !important;
  padding-top: 1vh !important;
}

.users-table-cell-body {
  font-family: "Latam Sans Regular" !important;
  color: black !important;
  font-size: 1.9vh !important;
  padding: 0px !important;
}

.cco-select, .role-select, .email-select {
  font-size: 1.9vh !important;
}

.email-input, .role-input, .edit-role-input, .cco-input {
  font-size: 1.8vh !important;
}

.edit-role-input::before {
  border-bottom: none !important;
}

.users-table-pagination {
  font-family: "Latam Sans Regular" !important;
  font-size: 1.5vh !important;
}