.critical-aircraft .ca-counter{
  background-color: #1979D9;
  height: 6.2vh;
  margin-top: 1vh;
  border-top-right-radius: 1vh;
  border-bottom-right-radius: 1vh;
  width: 28%;
  margin-left: 1.7vh;
  border-left: 1vh solid gray;
}

.critical-aircraft .ca-counter p{
  margin: 0;
  color: white;
  text-align: center;
  font-weight: bold;
  
}

.critical-aircraft .ca-counter .ca-counter-title{
  font-style: italic;
  font-size: 2.4vh;
  font-family: "Lato Bold Italic";
  margin-left: -0.7vh;
}

.critical-aircraft .ca-counter-count{
  font-size: 2.4vh;
  margin-left: -0.9vh;
}