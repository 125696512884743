.otp-veo-root{
  width: 100%;
  height: 100%;
  background-position: center;
  background-color: #10191D !important;
  overflow: hidden;

}

.otp-veo{
  flex-wrap: wrap;
  width: 50%;
  height: 96%;
  margin-left: 1vh;
  margin: auto;
  border: 0.2vh solid white;
}
.otp-veo>div{
  background-color: #10191D !important;
}

.otp-veo .otp-veo-header{
  display: flex;
  width: 100%;
  border-radius: 0.6vh;
  margin-bottom: 0.4vh;
}

.otp-veo > .otp-veo-header > .generic-title{
  width: 36%;
  margin-left: 1.5%;
  border-top: 1px solid white !important;
  border-bottom: 1px solid white !important;
}

.otp-veo .otp-veo-head-label{
  display: inline-flex;
  flex-direction: column;
  width: 14%;
  height: 100%;
  justify-content: center;
}

.otp-veo .otp-veo-head-label > p{
  font-family: Latam Sans Bold;
  margin: 0 0 0 2vh;
  color: white;
  font-size: 2.6vh;
}

.otp-veo .otp-veo-head-label > p.label-p2{
  font-size: 2.2vh;
}

.otp-veo .otp-veo-airlines-percents{
  display: flex;
  width: 43%;
  margin-top: 1%;
}

.otp-veo .otp-veo-airlines-percents > div{
  display: inline-block;
  width: 33%;
}

.otp-veo .otp-veo-airlines-percents > div > p{
  margin: 0;
  font-family: Roboto;
  font-weight: bold;
  color: white;
  background-color: #10191D;
  border: 1px solid white;
  border-radius: 1vh;
  text-align: center;
  width: 64%;
  font-size: 2.5vh;
  margin: 0 auto;
}

.otp-veo .otp-veo-airlines-percents > .otp-veo-latam > img{
  width: 70%;
  margin-left: 10%;
}

.otp-veo .otp-veo-airlines-percents > .otp-veo-azul > img{
  width: 70%;
  margin-left: 12%;
}

.otp-veo .otp-veo-airlines-percents > .otp-veo-gol > img{
  width: 70%;
  margin-left: 15%;
}

.otp-veo .otp-veo-top{
  display: flex;
  width: 100%;
  height: 15vh;
}

.otp-veo .otp-veo-daily{
  background-color: #363636;
  width: 100%;
  height: 60vh;
  border-radius: 1vh;
  margin-left: 0.4vh;
}

.otp-veo > p{
  color: white;
  margin: 0.4vh;
  font-family: 'Latam Sans Bold';
  margin-left: 1vh;
}

.otp-veo .generic-title{
  width: 32%;
}

@media only screen and (max-width: 1024px) {
  .otp-veo{
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .otp-veo-root{
    width: 100%;
    height: 100%;
    background-position: center;
  }

} 


@media only screen and (max-width: 768px) {
  body{
    background-color: #10191D !important;
  }
  .otp-veo-hidden{
    display: none;
  }


  .otp-veo > .otp-veo-header > .generic-title{
    width: 230px;
    border-left: 20px solid #7858D7 !important;
    border-right: 20px solid #7858D7 !important;
    height: 43px;
    margin-left: 1.5%;
    border-top: 1px solid white !important;
    border-bottom: 1px solid white !important;

  }
  .generic-title>p{
    font-size: 30px;

  } 
  .otp-veo{
    flex-wrap: wrap;

    margin: 0;
    border: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .otp-veo-root{
    background-position: center;
  }
}
@media only screen and (max-width: 414px) {
  body{
    background-color: #10191D !important;
  }

  .otp-veo > .otp-veo-header > .generic-title{
    width: 230px;
    border-left: 20px solid #7858D7 !important;
    border-right: 20px solid #7858D7 !important;
    height: 43px;
    margin-left: 1.5%;
    border-top: 1px solid white !important;
    border-bottom: 1px solid white !important;
    margin: auto;
  }
  .generic-title>p{
    font-size: 30px;

  } 
  .otp-veo{
    flex-wrap: wrap;
    width: 100%;
    height: 736px;
    margin: 0;
    border: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .otp-veo-root{
    width: 100%;
    height: 736px;
    background-position: center;
  }
}
@media only screen and (max-width: 375px) {
  .otp-veo > .otp-veo-header > .generic-title{
    width: 230px;
    border-left: 20px solid #7858D7 !important;
    border-right: 20px solid #7858D7 !important;
    height: 43px;
    margin-left: 1.5%;
    border-top: 1px solid white !important;
    border-bottom: 1px solid white !important;
    margin: auto;
  }
  .generic-title>p{
    font-size: 30px;

  } 
  .otp-veo{
    flex-wrap: wrap;
    width: 100%;
    height: 667px;
    margin: 0;
    border: 0;
  }

  .otp-veo-root{
    width: 100%;
    height: 667px;
    background-position: center;
    
  }
}

@media only screen and (max-width: 320px) {
  .otp-veo > .otp-veo-header > .generic-title{
    width: 230px;
    border-left: 20px solid #7858D7 !important;
    border-right: 20px solid #7858D7 !important;
    height: 43px;
    margin-left: 1.5%;
    border-top: 1px solid white !important;
    border-bottom: 1px solid white !important;
    margin: auto;
  }
  .generic-title>p{
    font-size: 30px;

  } 
  .otp-veo{
    flex-wrap: wrap;
    width: 100%;
    max-height: 812px;
    margin: 0;
    border: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .otp-veo-root{
    width: 100%;
    max-height: 812px;
    background-position: center;
  }
}

