.weather-unit {
  font-family: "Latam Sans Regular";
  display: inline-block;
  background-color: #10191d;
  border-radius: 0.7vh;
  height: 4vh;
  width: 48.2%;
  color: white;
  margin-top: 0.8vh;
  margin-left: 0.9%;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  font-size: 3vh;
  border: 0.2vh solid #ffc700;
}

.weather-unit .weather-info-left {
  display: inline-flex;
  width: 30%;
  height: 100%;
  text-align: center;
  margin-left: 0.2vh;
}

.weather-unit .weather-info-left .iata-code {
  width: 50%;
  height: 100%;
  font-weight: bold;
  color: #ffc700;
  text-align: left;
  margin-left: 0.5vh;
}

.weather-unit .weather-info-left .weather-icon {
  width: 50%;
  height: 100%;
  margin-top: 0.21vh;
}

.weather-unit .weather-info-right {
  display: inline-flex;
  width: 69%;
  overflow: hidden;
  border-left: 0.2vh solid #c9c9c9;
  white-space: nowrap;
  text-align: center;
}

.weather-unit .weather-info-right .weather-rawtext-static {
  font-weight: normal;
  color: white;
  width: 100%;
  text-align: center;
  margin-inline: 1vh;
}
