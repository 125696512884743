.weather {
  background-color: #363636;
  width: 100%;
  height: 43vh;
  border-radius: 4px;
}

.weather > .weather-group {
  display: block;
  /* height: 6vh; */
  width: 100%;
  margin-top: -3px;
  /* display: flex !important;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row; */
}

.weather > .carousell-dots {
  text-align: center;
  width: 100%;
  margin-top: 0.8vh;
}

.weather .weather-dot {
  cursor: pointer;
  height: 1.6vh;
  width: 1.6vh;
  margin: 0 0.4vh;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.weather .weather-fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

.weather .weather-dot.active,
.weather .weather-dot:hover {
  background-color: #717171;
}
