.theader-group{
  background-color: rgba(169, 172, 175, 0.267);
  color: #31363F;
  width: 68px;
}
.table-group{
  font-family: "Latam Sans Regular Italic";
  font-size: 14px;
  font-weight:lighter !important;
  font-style: italic;
  width: 622px;
  color: #31363F;
  padding: 0px;
}
.doble-group{
  width: 68px !important;
  height: 34px;
  font-weight:lighter !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(111, 113, 116) !important;
  border-collapse: collapse;
  padding: 0px;
}
.simple-group{
  height: 34px;
  width: 34px !important;
  font-weight:lighter !important;
  text-align: center;
  vertical-align: middle;
  padding: 0px;

  border-collapse: collapse;
}
.rojo-group{
  background-color: #EB5757;
  padding: 0px;
}
.amarillo-group{
  background-color: #FFC700;
  padding: 0px;
}
.borde-group{
  border-left: 1px solid  rgb(111, 113, 116) ;
}

.none-group{
  text-align: center;
  padding: 0px !important;
  border-color: unset !important;
  border-style: none !important;
  width: 100%;
}
.col1-group{
  width: 68px;
}