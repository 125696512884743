.otp{
  flex-wrap: wrap;
  height: 87vh;
  margin-left: 1vh;
}

.otp .otp-header{
  display: flex;
  width: 100%;
  background-color: #363636;
  border-radius: 0.6vh;
  margin-bottom: 0.4vh;
  height: 9vh;
}

.otp > .otp-header > .generic-title{
  width: 36%;
}

.otp .otp-head-label{
  display: inline-flex;
  flex-direction: column;
  width: 14%;
  height: 100%;
  justify-content: center;
}

.otp .otp-head-label > p{
  font-family: Latam Sans Bold;
  margin: 0 0 0 2vh;
  color: white;
  font-size: 2.6vh;
}

.otp .otp-head-label > p.label-p2{
  font-size: 2.2vh;
}

.otp .otp-airlines-percents{
  display: flex;
  width: 43%;
  margin-top: 1%;
}

.otp .otp-airlines-percents > div{
  display: inline-block;
  width: 31%;
}

.otp .otp-airlines-percents > div > p{
  margin: 0;
  font-family: Roboto;
  font-weight: bold;
  color: white;
  background-color: #10191D;
  border: 1px solid white;
  border-radius: 1vh;
  text-align: center;
  width: 64%;
  font-size: 3vh;
  margin: 0 auto;
}

.otp .otp-airlines-percents > .otp-latam > img{
  width: 70%;
  margin-left: 10%;
}

.otp .otp-airlines-percents > .otp-azul > img{
  width: 70%;
  margin-left: 12%;
}

.otp .otp-airlines-percents > .otp-gol > img{
  width: 70%;
  margin-left: 15%;
}

.otp .otp-top{
  display: flex;
  width: 100%;
  height: 15vh;
}

.otp .otp-daily{
  background-color: #363636;
  width: 100%;
  height: 60vh;
  border-radius: 1vh;
  margin-left: 0.4vh;
}

.otp > p{
  color: white;
  margin: 0.4vh;
  font-family: 'Latam Sans Bold';
  margin-left: 1vh;
}

.otp .generic-title{
  width: 32%;
}
