.counter-a{
  display: inline-flex;
  flex-wrap: wrap;
  background-color: #10191D;
  color: #F0D265;
  margin-left: 1em;
  width: 20.7%;
  border-radius: 4px;
  font-weight: bold;
}
  
.counter-title{
  font-family: "Lato Bold Italic";
  height: 2.8vh;
}
  
.counter-a p{
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

.counter-b{
  display: inline-flex;
  flex-wrap: wrap;
  background-color: #EB1451;
  color: white;
  margin-left: 1em;
  width: 20.7%;
  border-radius: 4px;
  font-weight: bold;
}
  
.counter-b p{
  display: inline-flex;
  width: 100%;
  justify-content: center;
}
