.LoginComp{
  display: flex;
  align-items: center;
  background-color: silver;
  width: 100%;
  height: 100%;
  background-image: url(./latam-plane.jpg);
  background-size: cover;
}

.LoginComp .LoginBox{
  position: relative;
  top: -6%;
  margin: 0 auto;
  background-color: white;
  height: 42%;
  min-height: 16vw;
  width: 27%;
  border-radius: 4%;
}

.LoginComp .LoginBox img{
  display: block;
  margin: 0 auto;
  height: 22%;
  margin-top: 15%;
  max-width: 90%;
}

.LoginComp .LoginButton{
  display: block;
  font-family: 'Latam Sans Bold';
  margin: 0 auto;
  margin-top: 18%;
  background-color: #011884;
  color: white;
  border: none;
  border-radius: 1vh;
  font-size: 2.2vh;
  height: 5vh;
  width: 38%;
  border-radius: 0.5vh;
}

@media only screen and (max-width: 700px) {
  .LoginComp .LoginBox{
    width: 66%;
    top: -20%;
    height: 40%;
  }

  .LoginComp .LoginBox img{
    display: block;
    margin: 0 auto;
    height: 26%;
    margin-top: 15%;
    max-width: 90%;
  }

  .LoginComp .LoginButton{
    font-size: 2.1vh;
    height: 5vh;
    width: 48%;
  }
}

.LoginComp .LoginButton:hover{
  cursor: pointer;
}

#login-loader {
  margin-top: 5vh;
}