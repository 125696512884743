$mycolor: white;
$duration: 15s;
$coordX: -100%;

.weather-rawtext-scroll-container{
  display: inline-flex;
  overflow: hidden;
  margin-inline: 1vh;
  width: 100%;
}

.weather-rawtext-str{
  color: $mycolor;
  display: inline-flex;
  font-weight: normal;
  white-space: nowrap;
  width: 100%;

  &__item {
    color: $mycolor;
    }
}

@-webkit-keyframes weather-scroll {
  25% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d($coordX, 0, 0);
    transform: translate3d($coordX, 0, 0);
  }
}
  
@keyframes weather-scroll {
  25% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d($coordX, 0, 0);
    transform: translate3d($coordX, 0, 0);
  }
}