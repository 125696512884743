$mycolor: white;
$duration: 15s;

.ca-reason-container{
  display: inline-flex;
  overflow: hidden;
  margin-inline: 1vh;
  width: 100%;
  white-space: nowrap;
}

.ca-fleet-container{
  font-family: "Latam Sans Bold";
  font-size: 2vh;
  text-align: center;
  overflow: hidden;
  margin-inline: 1vh;
  width: 45%;
  white-space: nowrap;
}

.ca-reason-str{
  color: $mycolor;
  display: inline-flex;
  font-weight: normal;
  white-space: nowrap;
  width: 100%;

  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: $duration;

  &__item {
    font-size: 2.3vh;
    color: $mycolor;
    }
}

@-webkit-keyframes ticker {
  25% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
  
@keyframes ticker {
  25% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
