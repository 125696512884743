.box1 {
  display: flex;
  background-color: #10191D;
  color: white;
  width: 99.1%;
  height: 14.6vh;
  border-left: 1.2vh solid #EA5659;
  box-shadow: 0px 0.1vh 0.1vh rgba(0, 0, 0, 0.25), inset 0px 0.1vh 0.1vh rgba(223, 226, 255, 0.34);
  border-radius: 0.8vh;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-bottom: 0.4vh;
  margin-top: 0.2vh;
}

.box1 .card-segment {
  margin-top: 0.8vh;
}

.box1 .item1 {
  display: flex;
  width: 19.1%;
}

.box1 .item1 img {
  width: 4.98vh;
  height: auto;
}

.box1 .item1-left {
  margin-left: 5%;
  font-style: italic;
  font-weight: 600;
  font-size: 1.95vh;
}

.box1 .item1 .item1-right .plane-flight-date {
  font-size: 1.5vh;
  text-align: center;
  font-family: 'Latam Sans Bold';
}

.box1 .item1 .item1-right .plane-number {
  font-size: 3.5vh;
  line-height: 2;
  font-family: 'Latam Sans Black';
  text-align: center;
}

.box1 .item1 .item1-right .plane-model-flight {
  font-size: 2vh;
  font-family: 'Latam Sans Bold Italic';
  text-align: center;
}

.box1 .item1 .sub-segment {
  display: inline-block;
  width: 46%;
  margin-top: 1vh;
}

.box1 .item1 .cf-status {
  height: 2.6vh;
  font-size: 2.5vh;
  font-family: 'Latam Sans Bold Italic';
}

.box1 .item1 .gtb {
  font-size: 2.6vh;
  color: #FF7D7D;
  font-family: 'Latam Sans Black Italic';
}

.box1 .item2 {
  flex-wrap: wrap;
  width: 15.2%;
  margin-top: 1.6vh;
}

.box1 .item2-cities {
  font-family: 'Latam Sans Bold';
  font-size: 3vh;
  color: #C9C9C9;
  text-align: center;
  width: 100%;

}

.box1 .item2-time {
  font-family: 'ZCOOL';
  text-align: center;
  width: 100%;
  font-size: 3vh;

}

.box1 .item2-arrival {
  font-family: 'ZCOOL';
  text-align: center;
  width: 100%;
  font-size: 3vh;
  margin-top: 0.6vh;
}

.box1 .item3 {
  width: 15.2%;
  text-align: center;
  flex-wrap: wrap;
}

.box1 .item3-title {
  color: #C9C9C9;
  font-size: 2.18vh;
  font-family: 'Latam Sans Bold Italic';
  width: 100%;
}

.box1 .item3-reason {
  display: block;
  color: white;
  font-weight: bold;
  font-style: normal;
  justify-content: center;
  margin-top: 2vh;
  font-size: 2.3vh;
  font-family: 'Latam Sans Black';
  text-transform: uppercase;
}

.box1 .item4 {
  width: 21.7%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.5%;
}

.box1 .item4 img {
  width: auto;
  height: 57%;
  position: relative;
  top: 15%;
}

.box1 .item4-box {
  display: flex;
  width: 34%;
  height: 46%;
  margin-left: 7%;
}

.box1 .item4 .item4-number {
  font-size: 1.94vh;
  color: #F9F9F9;
  font-family: 'Latam Sans Bold';
}

.box1 .item4 .item4-desc {
  font-size: 1.94vh;
  color: #C9C9C9;
  font-family: 'Latam Sans Bold';
  width: 150%;
}

.box1 .item4 .item4-labels {
  margin-left: 9%;
}

.box1 .item5 {
  width: 12.8%;
  text-align: center;
}

.box1 .item5 img {
  width: auto;
  height: 2.2vh;
}

.box1 .item5 .item5-inner-div {
  text-align: left;
  padding-left: 13%;
  margin-top: 0.6vh;
}

.box1 .item5 div,
.item5 p {
  width: 100%;
  text-align: left;
}

.box1 .item5 .item5-title {
  font-family: "Latam Sans Bold Italic";
  font-size: 2.1vh;
  color: #C9C9C9;
  text-align: center;
}

.box1 .item5-flight {
  font-size: 2.1vh;
  font-family: "Latam Sans Black";
}

.box1 .item5-route {
  font-size: 2.1vh;
  color: #C9C9C9;
}

.box1 .item5-time {
  font-size: 2.5vh;
  font-family: ZCOOL;
}

.box1 .item5-time img {
  margin-left: 5%;
}

.box1 .item6 {
  display: flex;
  height: 13vh;
  flex-wrap: wrap;
  width: 14.1%;
  text-align: center;
}

.box1 .item6-title {
  font-size: 2.08vh;
  font-family: "Latam Sans Bold Italic";
  color: #C9C9C9;
  width: 98%;
}

.box1 .item6-time {
  width: 100%;
  font-family: ZCOOL;
  color: #FF7D7D;
  font-size: 3.5vh;
}

.box1 .item6-flight {
  width: 100%;
  font-family: "Latam Sans Black";
  font-size: 2.9vh;
}

.box1 .item7 {
  display: none;
  flex-wrap: wrap;
  text-align: center;
  width: 7.8%;
}

.box1 .item7-title {
  font-family: "Latam Sans Bold Italic";
  font-size: 2.08vh;
  width: 99%;
  color: #C9C9C9;
}

.box1 .item7-line {
  width: 100%;
  font-size: 2.42vh;
}

.box1 .item7-code {
  width: 99%;
  font-weight: bold;
}

.box1 .item7-hr {
  font-family: ZCOOL;
}

.box1 .item8 {
  flex-wrap: wrap;
  width: 10.5%;
  text-align: center;
}

.box1 .item8 .item8-title {
  font-size: 2.08vh;
  width: 99%;
  font-family: "Latam Sans Bold Italic";
  color: #C9C9C9;
}

.box1 .item8 .item8-inner-div {
  margin-top: 1vh;
  height: 70%;
}

.box1 .item8 .item8-inner-div .cf-carrusel {
  height: 110%;
}

.box1 .item8 .item8-inner-div .MuiSvgIcon-root {
  width: 0.5em;
  height: 0.5em;
}

.box1 .item8 .item8-impact-type {
  width: 100%;
  font-weight: bold;
  text-align: center;
  font-size: 2vh;
}

.box1 .item8 img {
  width: auto;
  height: 2.25vh;
}

.box1 .item9 {
  display: inline-flex;
  flex-wrap: wrap;
  width: 16%;
  text-align: center;
}

.box1 .item9-title {
  font-family: "Latam Sans Bold Italic";
  font-size: 2.08vh;
  width: 99%;
  color: #C9C9C9;
}

.box1 .item9-inner-div {
  width: 99%;
  height: 70%;
  margin-left: 10%;
  margin-right: 10%;
}

.box1 .item9-data {
  height: 100%;
}

.box1 .item9-data-row {
  display: flex;
  justify-content: space-between;
}

.box1 .item9-plane-data {
  font-size: 2vh;
  font-family: "Latam Sans Bold";
  color: white;
  white-space: nowrap;
}

.box1 .item9 .item9-inner-div .item9-data .cf-carousel {
  height: 100%;
}

.box1 .item9 .item9-inner-div .item9-data .MuiSvgIcon-root {
  width: 0.5em;
  height: 0.5em;
}

.cf-no-data {
  width: 100%;
  color: white;
  margin-top: -2vh;
  font-weight: bold;
  font-style: normal;
  font-size: 3vh;
  text-align: center !important;
}

.display-none {
  display: none;
}

.no-data img {
  animation: MyDiv;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-direction: alternate;
}

@keyframes MyDiv {
  0%   {opacity: 0;;}
  100%  {opacity: 1;}
}