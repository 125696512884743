@font-face {
  font-family: "ZCOOL";
  src: url("./fonts/zcool_lite.woff2") format("woff2");
}

@font-face {
  font-family: "Ubuntu Bold";
  src: url("./fonts/Ubuntu-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Ubuntu Regular";
  src: url("./fonts/Ubuntu-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Lato Bold Italic";
  src: url("./fonts/Lato-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Latam Sans Black";
  src: url("./fonts/Latam_Sans_Black.woff2") format("woff2");
}

@font-face {
  font-family: "Latam Sans Black Italic";
  src: url("./fonts/Latam_Sans_Black_Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Latam Sans Bold";
  src: url("./fonts/Latam_Sans_Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Latam Sans Bold Italic";
  src: url("./fonts/Latam_Sans_Bold_Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Latam Sans Hairline";
  src: url("./fonts/Latam_Sans_Hairline.woff2") format("woff2");
}

@font-face {
  font-family: "Latam Sans Hairline Italic";
  src: url("./fonts/Latam_Sans_Hairline_Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Latam Sans Light";
  src: url("./fonts/Latam_Sans_Light.woff2") format("woff2");
}

@font-face {
  font-family: "Latam Sans Light Italic";
  src: url("./fonts/Latam_Sans_Light_Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Latam Sans Regular";
  src: url("./fonts/Latam_Sans_Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Latam Sans Regular Italic";
  src: url("./fonts/Latam_Sans_Regular_Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Latam Sans Extended";
  src: url("./fonts/Latam_Sans_Extended.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* @media only screen and (min-height: 980px) { */
  
  div.box1 .item2-cities{
    font-size: 2.8vh;
  }

  div.contingency-card{
    /* width: 174.1vh; */
    width: 32.75%;
  }

  div.temporal{
    /* width: 88vh; */
    width: 16.5%;
  }

  div.critical-aircraft{
    /* width: 88vh; */
    width: 16.5%;
  }

  div.layout{
    /* width: 87vh; */
    width: 16.5%;
  }

  div.otp{
    /* width: 87vh; */
    width: 16.4%;
  }

  div.generic-title p{
    font-size: 92%;
    white-space: nowrap;
    
  }

  div.critical-flights div.cf-counter-grp-a{
    margin-right: 9.6%;
  }

  div.critical-flights .critical-flight-unit{
    margin-left: 2%;
  }

  div.contingency-title p{
    font-size: 2.2vh;
  }

  div.box1 .item6-time{
    font-size: 3.2vh;
  }

  @media only screen and (max-width: 480px) { 
    
    div.punct-unit-table{
      height: 40vh;
    }

    div.weather > .weather-group {
      height: 65%;
    }
    
    div.veo-punctuality{
      height: 62%;
    }
  }

/*


  div.box1{
    width: 100%;
    white-space: nowrap;
  }

  div.box1 .item2-time, div.box1 .item2-cities{
    font-size: 2.8vh;
  }

  div.counter-a{
    width: 11.25vh;
  }

  div.counter-b{
    width: 11.25vh;
  }

  div.countergrp-a{
    margin-left: 0;
    width: unset;
  }

  div.countergrp-b{
    margin-left: 0;
  }

}
 */

