/* latam-scroll */

.latam-scroll {
  display: flex;
  height: 4vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color:#0B0F1B;
}

.latam-scroll .news .message-img{
  margin-right: 1vh;
  margin-left: 3vh;
}

.latam-scroll .news .last-message-img {
  margin-right: 2%;
}

.latam-scroll .news .message, .latam-scroll .news .executive{
  white-space: nowrap;
}
  
.latam-scroll .news img{
  width: auto;
  height: 2.7vh;
  position: relative;
  top: 0.5vh;
  margin-right: 1vh;
  margin-left: 1vh;
  transform: scaleX(-1);
}
  
.latam-scroll .news {
  display: flex;
  font-size: 2.7vh;
  font-weight: bold;
  color:#F0D265;
  position: absolute;
  /* width: 100%; */
  height: 100%;
  margin: 0;
  /* line-height: 50px; */
  text-align: center;
  /* Starting position */
  padding-left: 100%;
  /* Apply animation to this element */  
  -moz-animation: latam-scroll 70s linear infinite;
  -webkit-animation: latam-scroll 70s linear infinite;
  animation: latam-scroll 70s linear infinite;
}

.latam-scroll .news:hover  {
  animation-play-state: paused;
}

/* Move it (define the animation) */
@-moz-keyframes latam-scroll {
  to {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes latam-scroll {
  to {
    transform: translateX(-100%);
  }
}

@keyframes latam-scroll  {
  to {
    transform: translateX(-100%);
  }
}  
  
/* end latam scroll*/