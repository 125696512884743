.clock-group {
  width: 550vh;
}

.latam-title {
  display: flex;
  height: 7vh;
  width: 100%;
  background-color: #140964;
}

.latam-title img {
  display: inline-flex;
  height: auto;
  width: 14vh;
}

.latam-title > .img-left {
  margin-right: 16vh;
  margin-left: 5vh;
}

.latam-title > .img-right {
  margin-right: 7vh;
  margin-left: 16vh;
}

.latam-title .title-clocks {
  display: inline-block;
  width: 10%;
  background-color: yellow;
}

/* latam clock */
.my-clock {
  display: inline-flex;
  margin-top: 0.8vh;
  padding: 0vh 3.95vh;
  height: 5vh;
  background-color: #140964;
  margin-left: auto;
  margin-right: auto;
}

.my-clock .my-clock-title {
  font-family: Latam Sans Extended;
  color: #fff;
  margin: 0;
  padding-top: 0.5vh;
  padding-right: 1.3vh;
  font-weight: 700;
  font-size: 3.5vh;
}

.my-clock .my-clock-bkgd {
  display: inline-flex;
  padding-top: 0.4vh;
  padding-left: 0.57vh;
  padding-right: 0.57vh;
  margin-left: 0.66vh;
  border-radius: 4px;
  background-color: #0b0f1b;
}

.my-clock .my-clock-style {
  display: inline-flex;
  width: 9.4vh;
  height: 4.2vh;
  color: white;
  margin: 0 auto;
  border-radius: 4px;
  font-family: "ZCOOL";
  font-size: 3.5vh;
  font-weight: 500;
  align-items: center;
  justify-content: center;
}

.logo-right {
  text-align: right;
}

/* // nps .nps-contingency */
.nps-contingency {
  background: #000;
  border-left: 7px solid #ffc700;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  margin: 5px 0;
  padding: 5px 5px 5px 9px;
  width: 25vw;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: stretch;
  width: fit-content;
}

.nps-contingency h4,
.nps-contingency h3,
.nps-contingency p {
  margin: 0;
  color: white;
}

.nps-contingency h4,
.nps-contingency h3 {
  font-weight: bold;
  font-size: 1.6vh;
}

.nps-contingency h3 {
  font-size: 1.5vh;
}

.nps-contingency p {
  font-size: 1.2vh;
}

.nps-left {
  width: 40%;
}

.nps-left h4 {
  margin-bottom: 0.3vh;
}

.nps-left,
.nps-right {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nps-right {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.nps-rosa {
  display: flex;
  font-family: Lato Bold Italic;
  font-weight: bold;
  background: #eb1451;
  border-radius: 6px;
  margin-top: 2px;
  width: 100px;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2px;
  margin-left: 5px;
}

.nps-rosa h4 {
  font-size: 1vh;
  font-family: Latam Sans Regular;
  margin-top: 0.5vh;
  margin-bottom: -0.5vh;
}

.nps-rosa p {
  font-size: 2vh;
  margin-top: 0.3vh;
}
.ocultar-temporalmente{
  opacity: 0;
}

.nps-green {
  display: flex;
  font-family: Lato Bold Italic;
  font-weight: bold;
  background: darkolivegreen;
  border-radius: 6px;
  margin-top: 3px;
  width: 100px;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2px;
  margin-left: 5px;
}

.nps-green h4 {
  font-size: 1vh;
  font-family: Latam Sans Regular;
  margin-top: 0.5vh;
  margin-bottom: -0.5vh;
}

.nps-green p {
  font-size: 2vh;
  margin-top: 0.3vh;
}

.error-message {
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 0.8vh;
  font-weight: 10;
}
