/* Estilos generales para crew-standby */
.crew-standby {
  background-color: #363636;
  height: 43vh;
  width: 100%;
  border-radius: 4px;
}

.crew-standby .critical-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 8vh;
  margin-right: 1.5vh;
}

.crew-standby > .critical-header > .generic-title {
  width: 60%;
}

.crew-standby .crew-standby-main {
  margin: 0 auto;
  width: 100%;
  height: 32vh;
}

/* Estilos para el carrusel y contadores */
.crew-standby .cf-counter-grp {
  display: inline-flex;
  padding-top: 1vh;
  padding-right: 2vh;
}

.crew-standby .cf-counter-grp-b {
  display: inline-flex;
  margin: 0;
}

.crew-standby .cf-carousell-dots {
  display: block;
  text-align: center;
  width: 100%;
  height: 4.1vh;
  margin-top: -0.6vh;
}

.crew-standby .dot3 {
  cursor: pointer;
  height: 1.2vh;
  width: 1.2vh;
  margin: 0 0.4vh;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.crew-standby .cf-divisor {
  display: inline-block;
  position: relative;
  top: 37.3vh;
  left: 8.7%;
  width: 73vh;
  height: 0.4vh;
  background-color: #000000;
  transform: rotate(90deg);
  opacity: 80%;
  margin-right: 5%;
}

/* Estilos específicos para Brasil */
.bra-brasil-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5%;
  width: 100%;
}

.bra-brasil-time {
  font-size: 15px !important;
  width: 11.25%; /* (100% - 10%) / 8 */
  text-align: center;
}

.bra-brasil-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bra-summary-limops-colx2 {
  width: 13%;
}

.bra-summary-limops-col {
  width: 5.625%;
  font-size: 0.9vh;
  background-color: #10191d;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.3vh;
  border-radius: 10px;
}

.bra-summary-limops-sep {
  width: 1px;
  background-color: gray;
}

/* Estilos generales para las columnas */
.summary-limops-col {
  margin: 0 0.3vh;
  font-size: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilos para las filas de datos */
.crew .summary-limops-row-style {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
}

.crew .summary-limops-row-style div:first-child {
  width: 10%;
  font-size: 2.3vh !important;
  border: none;
}

.crew .summary-limops-row-style div {
  /* width: 5.625%; (100% - 10%) / 16 */
  background-color: #10191d;
  border-radius: 6px;
  font-size: 1.8vh;
  /* font-weight: bold !important; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.crew .summary-limops-row-style .red,
.crew .summary-limops-row-style .white {
  border-left-width: 5px;
  border-left-style: solid;
  background-color: #10191d !important;
}

.crew .summary-limops-row-style .red {
  border-left-color: rgb(235, 87, 87);
}

.crew .summary-limops-row-style .white {
  border-left-color: white;
}

/* Otros estilos específicos */
.bg-transparent {
  background-color: transparent !important;
}

.ato-casa {
  color: white;
  font-weight: bold;
  font-size: 12px;
  position: relative;
  left: 20%;
  top: 2%;
  width: 100px;
}

.crew .summary-limops-sep {
  width: auto;
}

.crew .title {
  background-color: #10191d;
  width: 99.4%;
}

.crew .title div {
  border: none;
  position: relative;
}

.crew .title .summary-limops-col::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: gray;
}

.header div {
  font-size: 2.5vh !important;
  border: none !important;
  margin: 0.1vh;
  border-radius: 5px;
  background-color: #10191d;
}

.oh {
  width: 10%;
}

.ato {
  font-size: 1.4vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mando,
.cabina {
  background-color: #10191d;
  font-size: 1.1vh;
}

.summary-limops-padding {
  width: 10.8%;
  margin: 0 2.5px;
  font-size: 2svh !important;
  height: 3vh;
  margin-top: 1vh;
}

.summary-limops-new-fltSummaryShift {
  margin: 0 0.3vh;
  font-size: 1.5vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 10.8%;
}
