.TabPanelOtp{
    background-color: #10191D ;
    
}
.AppBarOtp{
    background-color: #10191D !important;
}
.TabAppBarOtp{
    font-family: "Latam Sans Regular";
    font-size: 16px ;
    color: white ;
    width: 50% ;

}
.MuiTabs-flexContainer{
    width: 80%;
    margin: auto;
}

.MuiTab-root{
    max-width: 100% !important;
  
}
.MuiTabs-scroller>span{
    background-color: white;
}
@media only screen and (max-width: 768px) {
    .MuiBox-root{
    }
    .TabAppBarOtp{
        font-family: "Latam Sans Regular";
        font-size: 16px ;
        color: white ;
        width: 50% ;
    }
    .MuiTabs-flexContainer{
        width: 100% !important;
        margin: auto;
    }
}
@media only screen and (max-width: 414px) {
    .MuiBox-root{
        padding-left: 0% !important;
        padding-right: 0% !important;
        padding-top: 0% !important;
    }
    .TabAppBarOtp{
        font-family: "Latam Sans Regular";
        font-size: 16px ;
        color: white ;
        width: 50% ;
    }
    .MuiTabs-flexContainer{
        width: 100% !important;
        margin: auto;
    }
}

