.weather-config {
    height: 100%;
    margin-right: 3.5vh;
    min-width: 22em;
}

.w-title {
    font-family: "Latam Sans Bold";
    font-size: 2.8vh;
    color: #150067;
    margin-bottom: 3.5vh;
    min-width: 10em;
}

.w-tab {
    font-family: "Latam Sans Regular";
    color: #1A86F4;
    font-size: 2vh;
}

.w-filters {
    margin-top: 5vh;
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 10vh;
    row-gap: 3vh;
    width: 100%;
}

.desc {
    font-family: "Latam Sans Regular Italic";
    font-size: 2vh;
    color: #32314B;
    margin-top: 2vh;
}

.react-tabs__tab--selected {
    color: #32314B;
}

.w-buttons {
    margin-top: 5vh;
}

.last-mod {
    font-family: "Latam Sans Regular Italic";
    font-size: 1.6vh;
    color: #31363F;
    margin-bottom: 2vh;
}

/* Start Select styles */
.w-select {
    min-width: 30vh !important;
    max-width: 40vh !important;
}

.outlined-input {
    padding-top: 1vh !important;
    padding-bottom: 1vh !important;
    padding-left: 1vh !important;
}

.select:focus {
    background-color: transparent !important;
}

.MuiListItem-root.Mui-selected {
    background-color: rgba(197, 220, 250, 0.5) !important;
}

.MuiSelect-outlined.MuiSelect-outlined{
    font-family: "Latam Sans Regular" !important;
}

.option-all {
    font-size: 1.8vh !important;
    color: #32314B !important;
    font-family: "Latam Sans Bold" !important;
    background-color: #EEEFEE !important;
}

.w-option {
    font-size: 1.8vh !important;
    color: #32314B !important;
    font-family: "Latam Sans Regular" !important;
    padding-left: 3vh !important;
}
/* End Select styles */