.daily-news-root {
  margin-right: 3.5vh;
}

.create-post-card{
  border: 1px solid gray;
  border-radius: 1vh;
  padding: 1vh;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.create-post-card .create-post-title{
  font-family: 'Latam Sans Bold';
  color: #011884;
  margin: 0;
  font-size: 2.22vh;
}

.create-post-card .create-label{
  background-color: #F0F0F0;
  font-family: 'Latam Sans Regular';
  border-radius: 1vh;
  font-size: 2vh;
  padding: 2vh 2vh;
  transition: linear 0.4s;
  border: 0.2vh solid white;
}

.create-post-card .create-label:hover{
  border-color: #011884;
  cursor: pointer;
}

.message-container{
  overflow: auto;
}

.message-unit{
  /* color: black;
  display: flex;
  justify-content: space-between;
  border: 1px solid gray;
  border-radius: 1vh; */
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 1%;
}

.message-unit > p{
  margin: 0;

}

.message-unit .message-message{
  font-family: 'Latam Sans Regular Italic';
  font-size: 2vh;
  max-width: 80vh;
  overflow-wrap: break-word;
}

.message-unit .message-date {
  font-family: 'Latam Sans Regular';
  font-size: 2vh;
  text-align: center;
  max-width: 20vh;
}

.message-unit .message-status-active{
  font-family: 'Latam Sans Bold';
  padding: 0.2vh 1vh;
  text-align: center;
  background-color: #C5E8E6;
  color: #23635F;
  border-radius: 1.6vh;
  max-width: 10vh;
  font-size: 2vh;
}

.message-unit .message-status-expired{
  font-family: 'Latam Sans Bold';
  padding: 0.2vh 1vh;
  text-align: center;
  background-color: #C9C9C9;
  color: #616871;
  border-radius: 1.6vh;
  max-width: 10vh;
  font-size: 2vh;
}

.message-unit .message-status-pending{
  font-family: 'Latam Sans Bold';
  padding: 0.2vh 1vh;
  text-align: center;
  background-color: #e8e6c5;
  color: #9e9c20;
  border-radius: 1.6vh;
  max-width: 10vh;
  font-size: 2vh;
}

.message-unit .mess-ico{
  border-radius: 68%;
  padding: 1vh;
  cursor: pointer;
}

.message-unit .mess-ico:hover{
  background-color: silver;
  transition: linear 0.3s;
}

.mess-heads{
  font-family: 'Latam Sans Bold' !important;
  font-size: 2vh !important;
  color: #32314B !important;
}

.mess-head-status{
  max-width: 10vh;
  text-align: center;
}

.mess-head-startDate, .mess-head-endDate{
  max-width: 20vh;
  text-align: center;
}

.messages-table-pagination {
  font-family: "Latam Sans Regular" !important;
  font-size: 1em !important;
}

.dn-tab {
  font-family: "Latam Sans Regular";
  color: #1A86F4;
  font-size: 2vh;
}

.message-container .MuiTableCell-root {
  padding: 0.4vh 1vh;
}

.message-container .MuiTableRow-head .mess-heads:first-child{
  width: 80vh;
}
