.generic-title{
  display: inline-flex;
  margin: 1%;
  font-size: 2.48vh;
  width: 50.1%;
  height: 6vh;
  padding-left: 1.6%;
  color: white;
  font-weight: bold;
  background-color: #10191D;
  border-left: 1vh solid gray;
  border-right: 1vh solid gray;
  align-items: center;
  padding-right: 1%;
}
  
.generic-title img{
  display: inline-flex;
  height: 3vh;
  position: relative;
}

.generic-title p{
  font-family: 'Latam Sans Extended';
  display: inline-flex;
  font-size: 104%;
  height: 6vh;
  align-items: center;    
  text-transform: uppercase;
  margin-left: 1%;
  width: 100%;
}

.generic-title span{
  padding: 0.5vh 0;
  border-radius: 0.8vh;
  margin-left: 2vh;
  background-color: gray;
  width: 37%;
  text-align: center;
}

.generic-title .generic-update-labels{
  display: flex;
  flex-wrap: wrap;
}

.generic-title > div.generic-update-labels > p{
  font-family: 'Latam Sans Bold';
  margin: 0;
  margin-left: 3.5vh;
  font-size: 2vh;
  height: initial;
  text-transform: initial;
  display: initial;
}