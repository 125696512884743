.punctuality{
  background-color: #363636;
  height: 63.4%;
  width: 100%;
  border-radius: 0.6vh;
  margin-top: 0.4vh;
  color: white;
  height: 77.5vh;
}
.cell4-left>.row-percent-box-veo{
  width: 90%;
  border-radius: 0vh;
}
.cell4-puntuality>.row-percent-box-veo{
  width: 90%;
}
.cell4-puntuality>.row-percent-box-veo>.percent-data-veo{
  font-size: 3.6vh;
}

.punctuality-title{
  font-family: Latam Sans Extended;
  font-size: 2.3vh;
  color: white;
  margin: 0;
  font-weight: bold;
  margin-top: 0.4vh;
  padding-top: 0.4vh;
}

.punct-titles{
  font-family: Latam Sans Black;
  display: flex;
  margin: 0;
  width: 80%;
  margin-left: 18.8%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2.4vh;
  line-height: 2.4vh;
}
.punct-titles-afected{
  font-family: Latam Sans Black;
  margin: auto;
  margin-bottom: 0px;
  padding-top: 1%;
margin-left: 45%;
  font-size: 2.4vh;
  line-height: 2.4vh;
}
.latam-logo{
  height: 3.7vh;
}
.punct-titles > span{
  width: 20%;
  white-space: break-spaces;
}

.punctuality-unit{
  background-color: #10191D;
  width: 97%;
  border-radius: 0.4vh;
  align-items: center;
  margin: auto;
}

.punct-unit-table{
  width: 100%;
  height: 62vh ;

}

.punct-row{
  display: flex;
  font-family: 'Latam Sans Bold';
  width: 100%;
  height: 12%;
  align-items: center;
  text-align: center;
}

.punct-cell1{
  font-family: Latam Sans Black;
  font-size: 2.9vh;
  width: 20.5%;
  text-align: left;
  white-space: nowrap;
}
.punct-cell2{
  width: 15%;
  margin-left: 0.5%;
  
  /* background-color: lightcoral; */
}
.punct-cell3{
  width: 16%;
  font-size: 4vh;
  /* background-color: lightblue; */
}
.punct-cell4{
  width: 15%;

  /* background-color: lightcoral; */
}
.punct-cell5{
  width: 17.7%;
  font-size: 2.9vh;
  /* background-color: lightblue; */
}

.punct-delays-cell{
  width: 16%;
  /* background-color: lightcoral; */
}

.punct-delays-cell div:nth-of-type(1){
  border-right: 1px dashed gray;
  /* background-color: lightcoral; */
}

.punct-delays-cell div:nth-of-type(2){
  border-right: 1px dashed gray;
  /* background-color: lightcoral; */
}

.punct-delays-cell div:last-of-type{
  border-right: none !important;
}

.delay-unit{
  display: inline-block;
  width: 32%;
  height: 100%;
}

.delay-unit p{
  font-weight: bold;
  font-size: 2.2vh;
  margin: 0;
}

.punctuality > div:last-of-type > div:last-of-type{
  margin-top: 0;
}