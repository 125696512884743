.news-modal-style{
  background-color: white;
  margin: auto;
  border-radius: 1vh;
  padding: 1vh 2vh;
}

.modal-title{
  font-family: 'Latam Sans Bold';
  color: #011884;
  margin-top: 0;
  margin-bottom: 2vh;
}

.modal-message-content{
  background-color: #F0F0F0;
  font-family: 'Latam Sans Regular Italic';
  padding: 0.8vh;
  border: 0.2vh solid gray;
  border-radius: 1vh;
  width: 97%;
  height: 6rem;
}

.modal-pub-label{
  font-family: 'Latam Sans Regular';
  color: #011884;  
}

button.modal-post-btn{
  display: block;
  width: 50%;
  background-color: #011884;
  color: white;
  margin: auto;
}

button.modal-post-btn:hover, button.modal-post-btn:focus{
  background-color: #0024c4;
}

.news-modal-style .char-count{
  font-family: 'Latam Sans Regular';
  color: #252525;
  float: right;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}