.vw-options{
  height: 100%;
}

.vw-options .options-enter{
  width: 32%;
  height: 25%;
  background-color: #030E87;
}

.vw-options .options-button{
  display: flex;
  flex-direction: column;
  background-color: rgba(142,142,142,0.4);
  border-radius: 1.5vh;
  width: 35%;
  height: 18%;
  margin: auto auto;
  text-align: center;
  margin-bottom: 2%;
  padding-top: 1%;
  align-items: center;
  justify-content: center;
}

.vw-options .options-button p{
  margin: 0;
}

.vw-options .vw-options-content .options-back{
  background: transparent;
  color: white;
  margin: 0;
  margin-bottom: 5.4%;
  font-family: 'Latam Sans Bold';
  margin-left: 1%;
}

.vw-options-header{
  display: flex;
  width: 100%;
  height: 6%;
  background-color: #150067;
  justify-content: space-between;
}

.vw-options .options-button .options-btn-title{
  font-family: 'Latam Sans Bold';
  color: #030E87;
  font-size: 2.6vh
}

.vw-options-header img{
  margin: 0.4%;
  margin-left: 2%;
  height: 69%;
}

.vw-options-content{
  background-image: url(./latam_offset_sm.jpg);
  background-size: cover;
  background-position: center;
  padding-top: 1.2%;
  height: 93.6%;
}

.vw-options .options-button span.MuiButton-label{
  font-size: 1.6vh;
}

@media (min-width: 1024px) {
  .vw-options .options-button .options-btn-title{
    font-family: 'Latam Sans Bold';
    color: #030E87;
    font-size: 4vh
  }
}

