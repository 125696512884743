.critical-flights-row{
  display: inline-flex;
  width: 100%;
  color: white;
  font-family: "Latam Sans Bold";
  text-align: center;
  font-size: 2vh;
}

.critical-flights-tittle{
  display: inline-flex;
  font-family: "Latam Sans Bold";
  font-size: 2.5vh;
  width: 100%;
  color: white;
  text-align: center;
}
.critical-flights-colx2{
  width: 18%;
  padding: 0.6vh;
}
.critical-flights-col{
  width: 8%;
  padding: 0.6vh;
  margin-top: auto;
  margin-bottom: auto;
}
.critical-flights-flight{
  width: 12%;
  padding: 0.6vh;
  margin-top: auto;
  margin-bottom: auto;
}
.critical-flights-aircraft{
  width: 15%;
  padding: 0.6vh;
  margin-top: auto;
  margin-bottom: auto;
}
.critical-flights-Bussiness{
  width: 10%;
  background-color: #2100A7;
  border-radius: 1vh;
  padding: 0.6vh;
  height: 5%;
  margin-left: 1vh;
  margin-top: auto;
  margin-bottom: auto;
}
.critical-flights-Impact{
  width: 19%;
  border-radius: 1vh;
  padding: 0.6vh;
}
.critical-flights-row-style{
  width: 100%;
  color: white;
  margin-top: 0.8vh;
  background-color: #10191D;
  border-radius: 0.4vh;
  padding-bottom: 0.3vh;
  padding-top: 0.3vh;
}
.critical-flights-time{
  font-family: 'ZCOOL';
  font-weight: normal;
  font-size: 3vh;
}
.critical-flights-font{
  font-size: 3vh;
}
.critical-flights-separator{
width: 100%;
height: 0.1vh;
margin-top: 1vh;
margin-bottom: 1vh;
background-color: white;
}
.critical-flights-sep{
width: 0.1vh;
height: auto;
background-color: white;
}
.critical-flights-cols{
width: 98%;
padding-right:1%;
padding-left:1%;
}

.critical-flights-group{
  display: inline-flex;
  width: 100%;
}

.critical-flight-with-tittle{
  display: block;
  width: 97%;
}
.critical-flights-tittle-Bussiness{
  width: 13%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 2px;
  margin-right: 2px;
  

}
