.App {
  display: flex;
  flex-wrap: wrap;
  /* min-width: 1920px;
  min-height: 1080px; */
  height: 100vh;
  width: 610vh;
  background-color: #31363F;
  font-family: "Latam Sans Regular";
}

.vw-button {
  width: 26vh !important;
  height: 5vh !important;
  color: white !important;
  font-size: 1.7vh !important;
  font-family: "Latam Sans Regular" !important;
  font-weight: 600 !important;
  background-color: #030E87 !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  padding: 1vh 1vh 1vh 1vh !important;
  border-radius: 6px !important;
}
