#root {
    height: 100% !important;
}

.configuration {
    font-family: "Latam Sans Regular";
    height: 100% !important;
    background-color: white !important;
    overflow-y: auto !important;
}

.header-title {
    font-family: "Latam Sans Bold";
    font-size: 3.3vh;
    color: #F9F9F9;
    margin-left: 5%;
}

.subtitle {
    font-family: "Latam Sans Bold";
    text-align: center;
    font-size: 2vh;
    color: #252525;
    margin-top: 20%;
    margin-bottom: 3%;
}

.is-active {
    color: #280099 !important;
}

.menu-list, .MuiListItemText-root{
  font-family: "Roboto";
  
}

.configuration .is-active svg{
  color: #280099;
}

.MuiButtonBase-root:focus {
    background-color: transparent;
}

.ground-support-config{
    min-width: 24em;
}